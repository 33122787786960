import React, {useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../css/landing.module.scss';
import { companies } from './companies';

const LanguageSwitcher = ({currentLang, setCurrentLang, flag, setFlag }) => {
  const { t, i18n } = useTranslation();
  const switchLanguage = () => {
    // Toggle between 'fr' and 'ar'
    const newLang = currentLang === 'fr' ? 'ar' : 'fr';
    // const newFlag = currentLang === 'fr' ? '/react/assets/images/flag_france.png' : '/react/assets/images/flag_algeria.png';
    
    i18n.changeLanguage(newLang);
    // localStorage.setItem('i18nextLng', newLang);
    setCurrentLang(newLang);
    // setFlag(newFlag);
  };

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {
      
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts

  return (
    <div className={styles.on_off_toggle_fr} id="on-off-toggle-fr-mobile">
        {/* <div className={styles.lang}>ع</div> */}
        <button className={styles.lang} onClick={() => switchLanguage('ar')}
        {...(selectedCompany && {
                style: {
                  color: selectedCompany.colors.textColor,
                },
              })}>
          {t('language')}
          {/* <img className={styles.imageBg} src={flag} alt={currentLang === 'fr' ? 'ع' : 'F'} /> */}
        </button>

        
    </div>
  );
};

const LanguageSwitcherLanding = ({currentLang, setCurrentLang, flag, setFlag }) => {
  const { t, i18n } = useTranslation();
  const switchLanguage = () => {
    // Toggle between 'fr' and 'ar'
    const newLang = currentLang === 'fr' ? 'ar' : 'fr';
    // const newFlag = currentLang === 'fr' ? '/react/assets/images/flag_france.png' : '/react/assets/images/flag_algeria.png';
    
    i18n.changeLanguage(newLang);
    // localStorage.setItem('i18nextLng', newLang);
    setCurrentLang(newLang);
    // setFlag(newFlag);
  };

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {
      
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts



  return (
    <div className={styles.on_off_toggle_fr_landing} id="on-off-toggle-fr-mobile">
        {/* <div className={styles.lang}>ع</div> */}
        <button className={styles.lang} onClick={() => switchLanguage('ar')}
        {...(selectedCompany && {
                style: {
                  color: selectedCompany.colors.textColor,
                },
              })}>
          {t('language')}
          {/* <img className={styles.imageBg} src={flag} alt={currentLang === 'fr' ? 'ع' : 'F'} /> */}
        </button>

        
    </div>
  );
};

export  {LanguageSwitcher, LanguageSwitcherLanding};
