//localhost
// const backendLink = 'http://localhost/Jawda_base_V2_labo_nedjma/jawdaBackend/back_wpa_jawda/';
// const uploadsLink = 'http://localhost/Jawda_base_V2_labo_nedjma/jawdaBackend/uploads';

// //server 
const backendLink = '/laboNedjmaBackend/back_wpa_jawda/';
const uploadsLink = '/laboNedjmaBackend/uploads';

export {backendLink, uploadsLink};

