import React from 'react';
import stylesC from '../css/confetti.module.scss';

const Confetti = () => {
  return (
    <div className={stylesC.confetti}>
      {Array.from({ length: 13 }).map((_, index) => (
        <div key={index} className={stylesC.confettiPiece}></div>
      ))}
    </div>
  );
};

export default Confetti;
