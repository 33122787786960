import React from "react";
import styles from '../css/landing.module.scss';

const Footer = () => {
    return (
        <div className={styles.footer}>
            <a className={styles.linkFooter} href="https://thinline.agency/_jawda" target="_blank" rel="noopener noreferrer">
                <em className={styles.linkFooter}> Copyright <sup>©</sup> 2024 Eurl Thin Line Dz. All rights reserved. </em> 
            </a>
        </div>

    );
}

export default Footer;
