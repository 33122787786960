import React, { Suspense, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // eslint-disable-line react/react-in-jsx-scope
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InstallPrompt from '../components/InstallPrompt';
import MetaTags from '../components/MetaTagsIndex';
import Header from '../components/Header';
import { LanguageSwitcherLanding } from '../components/LanguageSwitcher';
import bg from '../assets/images/bg.png';
import styles from '../css/landing.module.scss';
import AlertModal from '../components/AlertModal.js';
import TermsOfUse from '../components/TermsOfUse';
import Footer from '../components/Footer';
import { companies } from "../components/companies";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';

const RecettesModal = React.lazy(() => import('../components/RecettesModal'));

const Landing = ({ isPWAInstallable, onInstallClick, isIos, currentLang, setCurrentLang, flag, setFlag }) => {
  const { t, i18n } = useTranslation();
  const [isTermsOfUseModalVisible, setIsTermsOfUseModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [formType, setFormType] = useState(null);
  const [isOpenRecipe, setIsOpenRecipe] = useState(false);
  // const [isTermsOfUseModalVisible, setIsTermsOfUseModalVisible] = useState(true);

  // const onAccept = () =>{
  //   localStorage.setItem('conditionsAccepted', 'true');
  //   setIsTermsOfUseModalVisible(false);
  //   console.log('user accepted');
  // }

  // useEffect(() => {
  //   const conditionsAccepted = localStorage.getItem('conditionsAccepted');
  //   if (conditionsAccepted) {
  //     setIsTermsOfUseModalVisible(false);
  //   }
  // }, []);

  // const onRefuse = () =>{

  // }

  // Force language to French on first load
  // React.useEffect(() => {
  //   if (!localStorage.getItem('i18nextLng')) {
  //     i18n.changeLanguage('fr');
  //   }
  // }, [i18n]);

  const onAccept = () => {
    localStorage.setItem('conditionsAccepted', 'true');
    setIsTermsOfUseModalVisible(false);
    if (formType === 'distributor') {
      navigate('/reclamation_d');
    } else {
      navigate('/reclamation_q');
    }
  }

  const navigate = useNavigate()

  const onRefuse = () => {
    setIsTermsOfUseModalVisible(false);
  }


  const goToForm = () => {
    // const conditionsAccepted = localStorage.getItem('conditionsAccepted');
    // setFormType('form');
    navigate('/reclamation_q');
    // if (conditionsAccepted) {
    //   navigate('/reclamation_q');
    // } else {
    //   setIsTermsOfUseModalVisible(true);
    // }
  }

  const goToDistributorForm = () => {
    // const conditionsAccepted = localStorage.getItem('conditionsAccepted');
    // setFormType('distributor');
    navigate('/reclamation_d');
    // if (conditionsAccepted) {
    //   navigate('/reclamation_d');
    // } else {
    //   setIsTermsOfUseModalVisible(true);
    // }
  }

  const [isDropdownOpen, setDropdownOpen] = useState(false); // State to manage dropdown visibility
  const [selectedCompany, setSelectedCompany] = useState(null); // State for selected company
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {

      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // Function to select a company and close the dropdown
  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    console.log(company);
    localStorage.setItem("selectedCompany", company.name);
    setDropdownOpen(false); // Close dropdown after selecting a company
  };

  const showAlerts = () => {
    setOpen(true);
  }

  const openRecipe = () =>{
    setIsOpenRecipe(true);
  }

  return (
    <div className={styles.main_container}>
      <MetaTags />
      {/* <button className={styles.switch_company} onClick={toggleDropdown}></button>
      {isDropdownOpen && (
        <ul className={styles.dropdownList}>
          {companies.map((company, index) => (
            <li
              key={index}
              onClick={() => handleCompanySelect(company)}
              className={styles.dropdownItem}
            >
              {company.name}
            </li>
          ))}
        </ul>
      )} */}
      <div className={styles.pageHeader} >
        <LanguageSwitcherLanding currentLang={currentLang} setCurrentLang={setCurrentLang} flag={flag} setFlag={setFlag} />
        <button className={styles.showAlerts} onClick={showAlerts}>
          <FontAwesomeIcon icon={faBell} className={styles.showAlertsIcon} />
        </button>
      </div>
      <Header />
      <div className={styles.formImgContainer}>
        <form className={styles.myForm}>
          <p className={styles.declarer}>{t('Bienvenue')}</p>
          <p className={styles.questionUser}> {t('AreYou')} </p>
          <div className={styles.log_product}>
            {/* Consumer Button */}
            <button
              type="button"
              onClick={goToForm}
              className={classNames(styles.navigation_button_user, styles.espace_product)}
              id="patn1"
              name="prod"
              value="QualityForm"
              // Conditionally set the style only if a company is selected
              {...(selectedCompany && {
                style: {
                  background: selectedCompany.colors.customerButton,
                },
              })}
            >
              {t('consumer')}
            </button>

            {/* Distributor Button */}
            <button
              type="button"
              onClick={goToDistributorForm}
              className={classNames(styles.navigation_button, styles.espace_product)}
              id="patn1"
              name="prod"
              value="QualityForm"
              // Conditionally set the style only if a company is selected
              {...(selectedCompany && {
                style: {
                  background: selectedCompany.colors.merchantButton,
                },
              })}
            >
              {t('distributor')}
            </button>

          </div>
          <div className={styles.lineWithText}>
            <hr />
            <span> {t('forYou')} </span>
            <hr />
          </div>
          <div className={styles.log_product}>
            <button
              type="button"
              onClick={openRecipe}
              className={classNames(styles.navigation_button_recipe, styles.espace_product)}
              id="patn1"
              name="prod"
              value="QualityForm"
              // Conditionally set the style only if a company is selected
              {...(selectedCompany && {
                style: {
                  background: selectedCompany.colors.merchantButton,
                },
              })}
            >
              {t('buttonForYou')}
            </button>
          </div>
        </form>
        {/* <img className={styles.imageBg} src={bg} alt="Jawda" /> */}
        <img
          className={styles.imageBg}
          src={selectedCompany ? selectedCompany.imageBg : bg}
          alt={selectedCompany ? `${selectedCompany.name} Background` : "Default Background"}
        />
      </div>
      {/* {isIos &&
    <div className={styles.iOSInstallSteps}>
      <p>Pour installer cette application :</p>
      <ol>
        <li>Appuyez sur le bouton de partage en bas du navigateur.</li>
        <li>Sélectionnez "Ajouter à l'écran d'accueil".</li>
      </ol>
    </div>}
  <InstallPrompt onInstallClick={onInstallClick} t={t} isPWAInstallable={isPWAInstallable && !isIos} alertsOpen={open} /> */}
      {/* <WhatsAppCallButton phoneNumber={'213776518290'} /> */}
      <Footer />
      <AlertModal open={open} setOpen={setOpen} />
      <TermsOfUse isVisible={isTermsOfUseModalVisible} onAccept={onAccept} onRefuse={onRefuse} />

      <Suspense>
        <RecettesModal isOpen={isOpenRecipe} setOpen={setIsOpenRecipe} />
      </Suspense>
      

      {/* <Suspense>
    <TermsOfUse isVisible={isTermsOfUseModalVisible} onAccept={onAccept} onRefuse={onRefuse}  />
  </Suspense> */}

    </div>
  )
};

export default Landing;

const WhatsAppCallButton = ({ phoneNumber }) => {
  // Function to initiate WhatsApp call
  const handleWhatsAppCall = () => {
    const formattedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove any non-digit characters
    const whatsappUrl = `https://wa.me/${formattedPhoneNumber}`;

    // Open WhatsApp in a new tab
    window.open(whatsappUrl, '_blank');
  };

  return (
    <button className={styles.whatsAppButton} onClick={handleWhatsAppCall}>
      <FontAwesomeIcon icon={faWhatsapp}
        className={styles.callIcon} />
    </button>
  );
};