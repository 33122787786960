import React, { useState ,forwardRef, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { fr, arSA } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import ClearIcon from '@mui/icons-material/Clear';
import classNames from 'classnames';
import styles from '../css/reclamation.module.scss'; // Adjust the path to your CSS module
import '../css/datePicker.scss'; 
import { Button, IconButton } from '@mui/material';
import { companies } from './companies';

// Register the French locale globally
registerLocale('fr', fr);
setDefaultLocale('fr');

export const DatePickerComponent = ({selectedDate,setSelectedDate, onBlur}) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef(null);
  const localeMap = {
    fr: fr,
    ar: arSA,
  };

  const toggleDatePicker = (e) => {
    setIsOpen(!isOpen);
  };

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {
      
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts



  const CustomInput = forwardRef(({ value, onClick, placeholder }, ref) => (
    <input
      className={styles.formControl}
      type="text"
      onClick={(e) => {
        console.log("CustomInput clicked");
        toggleDatePicker(e)
      }}
      value={value}
      ref={ref}
      placeholder={t('datePlaceholder')}
      readOnly
      name="date_fabrication"
      dir={i18n.language === 'fr' ? 'ltr' : 'rtl'}
      {...(selectedCompany && {
            style: {
              border: selectedCompany.colors.inputBorder,
              color: selectedCompany.colors.typeTextColor,
            },
          })}
    />
  ));

  const today = new Date();



  return (
    <div className={styles.labelDate}>
      <div
        id="datepicker"
        className={classNames(styles.inputGroup, 'date', styles.datepicker)}
        data-date-format="dd-mm-yyyy"
      >
        <DatePicker
          selected={selectedDate}
          onChange={(date) => {
            setIsOpen(false);
            setSelectedDate(date);
            onBlur();
          }}
          dateFormat="dd-MM-yyyy"
          maxDate={today}
          locale={localeMap[i18n.language]} // Set the locale dynamically
          customInput={
          <div className={styles.customInputWrapper}>
            <CustomInput
              onClick={toggleDatePicker}
              placeholderText={t('datePlaceholder')}
              value={selectedDate ? selectedDate.toLocaleDateString() : ''}
              onInputChange={(e) => {
                if (!e.target.value) {
                  setSelectedDate(null);
                }
              }}
            />
            {selectedDate !== null && <IconButton
              className={i18n.language === 'fr' ? styles.clearIcon : styles.clearIconAr}
              onClick={(event) => {
              event.preventDefault(); // Prevent default button behavior
              event.stopPropagation(); // Stop event from bubbling up
              setSelectedDate(null); // Clear the selected date
            }} 
              aria-label="clear"
            >
              <ClearIcon /> {/* You can replace ClearIcon with any icon */}
            </IconButton>

            }
            
          </div>
        }
          wrapperClassName={styles.reactDatepickerWrapper}
          className={styles.reactDatepicker}
          open={isOpen}
          onClickOutside={() => setIsOpen(false)}
          onClick={toggleDatePicker}
          ref={datePickerRef}
        />
        <span
        className={i18n.language === 'fr' ? styles.inputGroupAddon : styles.inputGroupAddonArabic}
        onClick={toggleDatePicker}>
          <FontAwesomeIcon icon={faCalendarDays} 
            {...(selectedCompany && {
            style: {
              color: selectedCompany.colors.iconColor,
            },
          })}
          />
        </span>
      </div>
    </div>
  );
};
  
