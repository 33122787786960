import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from '../css/reclamation.module.scss';
import { backendLink } from '../config';
import { companies } from './companies';



const products = [
  { id: 1, text: 'Savora Clean vanille' },
  { id: 2, text: 'ChocoVibe' },
  { id: 3, text: 'AquaZen 500 ml' },
  { id: 4, text: 'Fruitélia fraise 1L ' },
  { id: 5, text: 'CroqueDélice' },
  { id: 6, text: 'Fresh’Ola' },
  { id: 7, text: 'NutriMix' },
  { id: 8, text: 'BlissCafé' },
  { id: 9, text: 'VitaLime' },
  { id: 10, text: 'HydraGloss' }
];

export const DropDown = ({ placeholder, setProduct,
  isVisible, setProductVisibility, setProductInputsVisibility }) => {

  const [isListDisplayed, setListVisibility] = useState(false);
  const modalContentRef = useRef(null);
  const { t, i18n } = useTranslation();

  const toggleList = () => {
    console.log('list toggled', isListDisplayed);
    setListVisibility(!isListDisplayed);
  }

  const handleClickOutside = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      setListVisibility(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  const onItemSelected = (item) => {
    toggleList();
    if (setProductInputsVisibility !== null) {
      setProductInputsVisibility(false);
      setProduct(item.text);
      setProductVisibility(true);
    }
  }

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {

      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


  return (
    <div ref={modalContentRef} className={classNames(styles.wilayaContainer, styles.productDropdownContainer, styles.width)}
      style={{ display: isVisible ? 'block' : 'none' }}>
      <div className={styles.dropdownContainerr}>
        <div className={styles.customDropdownn}>
          <input type="text" id="productDropdown"
            className={classNames(i18n.language === 'fr' ? styles.customDropdownInput : styles.customDropdownArabicInput,
              styles.pointedArea)}
            name="product" placeholder={placeholder}
            onClick={toggleList} readOnly dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            {...(selectedCompany && {
              style: {
                border: selectedCompany.colors.inputBorder,
                color: selectedCompany.colors.textColor,
              },
            })}
          />
          <div className={classNames(styles.productList, { [styles.hidden]: !isListDisplayed })} id="productList"
            {...(selectedCompany && {
              style: {
                border: selectedCompany.colors.inputBorder,
                '--scrollbar-thumb': selectedCompany ? selectedCompany.colors.inputBorder : '#1c1ccb',
                '--scrollbar-track': selectedCompany ? selectedCompany.colors.inputBorder : '#f1f1f1',
              },
            })}>
            {products.map((product) => (
              <div
                key={product.id}
                className={styles.productListItem}
                onClick={() => onItemSelected(product)}
              >
                {product.text}
              </div>
            ))}
          </div>
          <div className={i18n.language === 'fr' ? styles.arrow : styles.arrowLeft} id="productArrow" onClick={toggleList}
            {...(selectedCompany && {
              style: {
                color: selectedCompany.colors.iconColor,
              },
            })}>&#9660;</div>
        </div>
      </div>
    </div>
  );
}

export const DropDownWilaya = ({ setWilaya, setCommuneList, wilaya, setCommune, onScroll }) => {
  const { t, i18n } = useTranslation();
  const [isListDisplayed, setListVisibility] = useState(false);
  const modalContentRef = useRef(null);
  const [wilayas, setWilayas] = useState([]);
  const [wilayaItem, setWilayaItem] = useState('');

  const toggleList = () => {
    onScroll();
    console.log('list toggled', isListDisplayed);
    setListVisibility(!isListDisplayed);
  }

  // const onItemSelected = (item) => {
  //   toggleList();
  //   const matches = communes.filter(commune => commune.wilaya_id === item.id);
  //   setCommune('');
  //   setCommuneList(matches);
  //   let wilayasel = i18n.language === 'ar' ? item.text_ar : item.text
  //   setWilaya(wilayasel);
  // }

  const onItemSelected = async (item) => {
    let wilayasel = item.text;
    setWilayaItem(item);
    setWilaya(wilayasel);

    toggleList();
    setCommuneList([]);
    setCommune('');


    try {
      const response = await fetch(`${process.env.REACT_APP_DATA_URL}/filter_communes.php?wilaya_id=${item.id}`);
      // const response = await fetch(`http://localhost/jawdaBackend/back_wpa_jawda/filter_communes.php?wilaya_id=${item.id}`);
      if (!response.ok) {
        throw new Error(`Error fetching communes data: ${response.statusText}`);
      }
      const data = await response.json();
      const matches = data.filter(commune => commune.wilaya_id === item.id);
      setCommuneList(matches);
      // let wilayasel = i18n.language === 'ar' ? item.text_ar : item.text;
      // let wilayasel = item.text;
      // setWilayaItem(item);
      // setWilaya(wilayasel);
    } catch (error) {
      console.error('Error fetching communes.json:', error);
    }
  };

  const handleClickOutside = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      setListVisibility(false);
    }
  };

  useEffect(() => {
    const fetchWilayas = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_DATA_URL}/wilayas.json`);
        // const response = await fetch(`${process.env.PUBLIC_URL}/data/wilayas.json`);
        if (!response.ok) {
          throw new Error(`Error fetching wilayas data: ${response.statusText}`);
        }
        const data = await response.json();
        setWilayas(data);
      } catch (error) {
        console.error('Error fetching wilayas.json:', error);
      }
    };

    fetchWilayas();
  }, []);

  useEffect(() => {
    if (isListDisplayed) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isListDisplayed]);

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {

      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


  return (
    <div ref={modalContentRef} className={classNames(styles.wilayaContainer, styles.productDropdownContainer, styles.width)}>
      <div className={styles.dropdownContainerr}>
        <div className={styles.customDropdownn}>
          <input type="text" id="productDropdown" className={classNames("dropdown-input", styles.pointedArea)} name="product"
            placeholder={t('chooseWilaya')}
            onClick={toggleList} readOnly value={
              wilayaItem !== ''
                ? (
                  i18n.language === 'fr'
                    ? `${wilayaItem.id} - ${wilayaItem.text} (${wilayaItem.text_ar})`
                    : `${wilayaItem.id} - ${wilayaItem.text_ar}`
                )
                : ''
            }

            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            {...(selectedCompany && {
              style: {
                border: selectedCompany.colors.inputBorder,
                color: selectedCompany.colors.textColor,
              },
            })}
          />
          <div className={classNames(styles.productList, { [styles.hidden]: !isListDisplayed })}
            id="productList"
            {...(selectedCompany && {
              style: {
                border: selectedCompany.colors.inputBorder,
                '--scrollbar-thumb': selectedCompany ? selectedCompany.colors.inputBorder : '#1c1ccb',
                '--scrollbar-track': selectedCompany ? selectedCompany.colors.inputBorder : '#f1f1f1',
              },
            })}
            style={{maxHeight:"328px"}}
            >
            {wilayas.map((wilaya) => (
              <div
                key={wilaya.id}
                className={i18n.language === 'fr' ? styles.productListItem : styles.productListItemArabic}
                onClick={() => onItemSelected(wilaya)}
              >
                {i18n.language === 'fr' ? wilaya.id + ' - ' + wilaya.text + ` (${wilaya.text_ar})` : wilaya.id + '- ' + wilaya.text_ar}
              </div>
            ))}
          </div>
          <div className={i18n.language === 'fr' ? styles.arrow : styles.arrowLeft} id="productArrow" onClick={toggleList}
            {...(selectedCompany && {
              style: {
                color: selectedCompany.colors.iconColor,
              },
            })}
          >&#9660;</div>
        </div>
      </div>
    </div>
  );
}

export const DropDownCommune = ({ communes, setCommune, commune }) => {
  const { t, i18n } = useTranslation();
  const [isListDisplayed, setListVisibility] = useState(false);
  const modalContentRef = useRef(null);
  const [communeItem, setCommuneItem] = useState('');

  useEffect(()=>{
    if(commune===''){
      setCommuneItem('');
    }
  },[commune]);

  const toggleList = () => {
    setListVisibility(!isListDisplayed);
  }

  const onItemSelected = (item) => {
    toggleList();
    setCommuneItem(item);
    // let selCommune = i18n.language === 'fr' ? item.name : item.ar_name
    let selCommune = item.name
    setCommune(selCommune);
  }

  const handleClickOutside = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      setListVisibility(false);
    }
  };

  useEffect(() => {
    if (isListDisplayed) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isListDisplayed]);

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {

      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


  return (
    <div ref={modalContentRef} className={classNames(styles.wilayaContainer, styles.productDropdownContainer, styles.width)}>
      <div className={styles.dropdownContainerr}>
        <div className={styles.customDropdownn}>
          <input type="text" id="productDropdown"
            className={classNames("dropdown-input", styles.pointedArea)}
            name="product"
            placeholder={t('chooseCommune')}
            onClick={toggleList} readOnly 
            // value={commune}
            value={
              communeItem !== ''
                ? (
                  i18n.language === 'fr'
                    ? `${communeItem.name}`
                    : `${communeItem.ar_name}`
                )
                : ''
            }
            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            {...(selectedCompany && {
              style: {
                color: selectedCompany.colors.iconColor,
                border: selectedCompany.colors.inputBorder
              },
            })}
          />
          {communes !== null && (
            <div className={classNames(styles.productList, { [styles.hidden]: !isListDisplayed })}
              id="productList"
              style={{maxHeight:"267px"}}
              {...(selectedCompany && {
                style: {
                  border: selectedCompany.colors.inputBorder
                },
              })}>
              {communes.map((commune) => (
                <div
                  key={commune.id}
                  className={i18n.language === 'fr' ? styles.communeListItem : styles.communeListItemArabic}
                  onClick={() => onItemSelected(commune)}
                >
                  {i18n.language === 'fr' ? commune.name : commune.ar_name}
                </div>
              ))}
            </div>
          )}
          <div className={i18n.language === 'fr' ? styles.arrow : styles.arrowLeft} id="productArrow"
            onClick={toggleList}
            {...(selectedCompany && {
              style: {
                color: selectedCompany.colors.iconColor,
              },
            })}>&#9660;</div>
        </div>
      </div>
    </div>
  );
}

export const DropDownLocation = ({ setWilaya, setCommune, wilaya, commune, onScroll }) => {
  const [communesList, setCommunesList] = useState(null);
  return (
    <div>
      <DropDownWilaya setCommuneList={setCommunesList} setWilaya={setWilaya} wilaya={wilaya} setCommune={setCommune} onScroll={onScroll} />
      <DropDownCommune communes={communesList} setCommune={setCommune} commune={commune} />
    </div>
  )
}

// export const DropDownCommercant = ({ placeholder, product, setProduct,
//   isVisible, setProductVisibility, setProductInputsVisibility, productVisibility }) => {

//   const [isListDisplayed, setListVisibility] = useState(false);
//   const [allSelected, setAllSelected] = useState(false);
//   const modalContentRef = useRef(null);
//   const { t, i18n } = useTranslation();

//   const toggleList = () => {
//     console.log('list toggled', isListDisplayed);
//     setListVisibility(!isListDisplayed);
//   }

//   const handleClickOutside = (event) => {
//     if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
//       setListVisibility(false);
//     }
//   };

//   useEffect(() => {
//     if (isVisible) {
//       document.addEventListener('mousedown', handleClickOutside);
//     } else {
//       document.removeEventListener('mousedown', handleClickOutside);
//     }
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [isVisible]);

//   // const onItemSelected = (item) => {
//   //   toggleList();

//   //   if (setProductInputsVisibility !== null) {
//   //     const currentProducts = product; // Get the current value of the product array

//   //     // Check if there are less than 3 elements in the product array before adding the new one
//   //     if (currentProducts.length < 3) {
//   //       setProduct([...currentProducts, item.text]); // Add the selected product to the array
//   //       setProductVisibility(true);
//   //       console.log([...currentProducts, item.text]);
//   //       // Set visibility for the new product
//   //       const updatedVisibility = [...productVisibility, true]; // Add true for the new product
//   //       setProductVisibility(updatedVisibility);

//   //       if(currentProducts.length === 2) setProductInputsVisibility(false);
//   //     } else {
//   //       console.log('Product list already contains 3 elements.');
//   //     }
//   //   }
//   // };

//   const onItemSelected = (item) => {
//     toggleList();

//     if (setProductInputsVisibility !== null) {
//       const currentProducts = product; // Get the current value of the product array

//       // Check if there are less than 3 elements in the product array before adding the new one
//       if (currentProducts.length < 3) {
//         setProduct([...currentProducts, item.text]); // Add the selected product to the array
//         setProductVisibility(true);
//         console.log([...currentProducts, item.text]);

//         // Set visibility for the new product
//         const updatedVisibility = [...productVisibility, true]; // Add true for the new product
//         setProductVisibility(updatedVisibility);

//         if (currentProducts.length === 2) setProductInputsVisibility(false);
//       } else {
//         console.log('Product list already contains 3 elements.');
//       }
//     }
//   };

//   // Filter out the selected products from the list
//   const filteredProducts = products.filter((productItem) => !product.includes(productItem.text));

//   // Select or deselect all products
//   const selectAllItems = () => {
//     if (!allSelected) {
//       // Select all products
//       const allProducts = products.map((item) => item.text);
//       setProduct(allProducts);
//       setProductVisibility(true);
//       setAllSelected(true); // Mark all items as selected
//     } else {
//       // Deselect all products
//       setProduct([]);
//       setProductVisibility(false);
//       setAllSelected(false); // Mark all items as deselected
//     }
//   };


//   return (
//     <div ref={modalContentRef} className={classNames(styles.wilayaContainer, styles.productDropdownContainer, styles.width)}
//       style={{ display: isVisible ? 'block' : 'none' }}>
//       <div className={styles.dropdownContainerr}>
//         <div className={styles.customDropdownCommercant}>
//           <input type="text" id="productDropdown" 
//           className={classNames(i18n.language === 'fr' ? styles.customDropdownInput : styles.customDropdownArabicInput,
//                       styles.pointedArea)}
//            name="product" placeholder={placeholder}
//             onClick={toggleList} readOnly dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
//           />
//           <div className={classNames(styles.productList, { [styles.hidden]: !isListDisplayed })} id="productList">
//             <div
//               key={product.id}
//               className={styles.productListItem}
//               onClick={() => selectAllItems()}
//             >
//               Tous les produits
//             </div>
//             {filteredProducts.map((product) => (
//               <div
//                 key={product.id}
//                 className={styles.productListItem}
//                 onClick={() => onItemSelected(product)}
//               >
//                 {product.text}
//               </div>
//             ))}
//           </div>
//           <div className={i18n.language === 'fr' ? styles.arrow : styles.arrowLeft} id="productArrow" onClick={toggleList}>&#9660;</div>
//         </div>
//       </div>
//     </div>
//   );
// }

export const DropDownCommercant = ({
  placeholder,
  product,
  setProduct,
  isVisible,
  setProductVisibility,
  setProductInputsVisibility,
  productVisibility,
  tous,
  setTous
}) => {
  const [isListDisplayed, setListVisibility] = useState(false);
  const [allSelected, setAllSelected] = useState(false); // Track if all products are selected
  const modalContentRef = useRef(null);
  const { t, i18n } = useTranslation();

  const toggleList = () => {
    setListVisibility(!isListDisplayed);
  };

  const handleClickOutside = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      setListVisibility(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  const onItemSelected = (item) => {
    toggleList();
    setTous(false);

    if (setProductInputsVisibility !== null) {
      const currentProducts = product; // Get the current value of the product array

      // Check if there are less than 3 elements in the product array before adding the new one
      if (currentProducts.length < 3) {
        setProduct([...currentProducts, item.text]); // Add the selected product to the array
        setProductVisibility(true);
        console.log([...currentProducts, item.text]);

        // Ensure productVisibility is an array before updating it
        const updatedVisibility = Array.isArray(productVisibility) ? [...productVisibility, true] : [true];
        setProductVisibility(updatedVisibility);

        if (currentProducts.length === 2) setProductInputsVisibility(false);
      } else {
        console.log('Product list already contains 3 elements.');
      }
    }
  };

  // Select or deselect all products
  const selectAllItems = () => {
    toggleList();
    // if (!allSelected) {
    //   // Select all products
    //   // const allProducts = products.map((item) => item.text);
    //   // setProduct(allProducts);
    //   // setProductVisibility(allProducts.map(() => false)); // Set visibility for all selected products
    //   // setAllSelected(true); // Mark all items as selected
    //   // setTous(true);

    // } else {
    //   // Deselect all products
    //   setProduct([]);
    //   setProductVisibility([]); // Reset visibility to an empty array
    //   setAllSelected(false); // Mark all items as deselected
    // }
    const allProducts = products.map((item) => item.text);
    setProduct([t('allProducts')]);
    setProductVisibility([true]); // Set visibility for all selected products
    setAllSelected(true); // Mark all items as selected
    setTous(true);
    setProductInputsVisibility(false);
  };

  // Filter out the selected products from the list
  const filteredProducts = products.filter((productItem) => !product.includes(productItem.text));

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {

      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


  return (
    <div
      ref={modalContentRef}
      className={classNames(styles.wilayaContainer, styles.productDropdownContainer, styles.width)}
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <div className={styles.dropdownContainerr}>
        <div className={styles.customDropdownCommercant}>
          <input
            type="text"
            id="productDropdown"
            className={classNames(
              i18n.language === 'fr' ? styles.customDropdownInput : styles.customDropdownArabicInput,
              styles.pointedArea
            )}
            name="product"
            // placeholder={allSelected ? 'Tous les produits' : placeholder} // Show 'Tous les produits' when all products are selected
            placeholder={placeholder}
            onClick={toggleList}
            readOnly
            // value={allSelected ? 'Tous les produits' : ''}
            value='' 
            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            {...(selectedCompany && {
              style: {
                color: selectedCompany.colors.textColor,
                border: selectedCompany.colors.inputBorder,
              },
            })}
          />
          <div className={classNames(styles.productList, { [styles.hidden]: !isListDisplayed })}
            id="productList"
            {...(selectedCompany && {
              style: {
                color: selectedCompany.colors.textColor,
                border: selectedCompany.colors.inputBorder,
              },
            })}>
            {/* <div className={styles.productListItem} onClick={selectAllItems}>
              {allSelected ? 'Désélectionner tout' : 'Sélectionner tout'}
            </div> */}
            <div 
              className={styles.productListItem}
              onClick={selectAllItems}
              dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
              {/* {'Sélectionner tout'} */}
              {t('selectAll')}
            </div>
            {filteredProducts.map((product) => (
              <div
                key={product.id}
                className={styles.productListItem}
                onClick={() => onItemSelected(product)}
              >
                {product.text}
              </div>
            ))}
          </div>
          <div
            className={i18n.language === 'fr' ? styles.arrow : styles.arrowLeft}
            id="productArrow"
            onClick={toggleList}
            {...(selectedCompany && {
              style: {
                color: selectedCompany.colors.iconColor,
              },
            })}
          >
            &#9660;
          </div>
        </div>
      </div>
    </div>
  );
};

const sources = [
  { name: 'Mr W.B' },
  { name: 'Mr A.B' },
  { name: 'Mr C.D' },
  { name: 'Mr E.F' },
  { name: 'Mr G.H' },
  { name: 'Mr I.M' }
];

export const DropDownSource = ({ setSource, source }) => {
  const { t, i18n } = useTranslation();
  const [isListDisplayed, setListVisibility] = useState(false);
  const modalContentRef = useRef(null);
  const dropdownListRef = useRef(null);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState('auto'); // Store max height of dropdown

  const toggleList = () => {
    setListVisibility(!isListDisplayed);
  };

  const onItemSelected = (item) => {
    toggleList();
    setSource(item.name);
  };

  const handleClickOutside = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      setListVisibility(false);
    }
  };

  useEffect(() => {
    if (isListDisplayed && modalContentRef.current) {
      const rect = modalContentRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const availableSpaceBelow = windowHeight - rect.bottom;
      const dropdownHeight = dropdownListRef.current?.scrollHeight || 0;

      // Set the max height of the dropdown based on the available space below the input
      if (dropdownHeight > availableSpaceBelow) {
        setDropdownMaxHeight(`${availableSpaceBelow - 30}px`); // Leave some space for padding/margin
      } else {
        setDropdownMaxHeight('auto'); // If there's enough space, no need to limit height
      }
    }
  }, [isListDisplayed]);

  useEffect(() => {
    if (isListDisplayed) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isListDisplayed]);

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {

      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


  return (
    <div ref={modalContentRef} className={classNames(styles.wilayaContainer, styles.productDropdownContainer, styles.width)}>
      <div className={styles.dropdownContainerr}>
        <div className={styles.customDropdownnSource}>
          <input
            type="text"
            id="productDropdown"
            className={classNames("dropdown-input", styles.pointedArea)}
            name="product"
            placeholder={t('chooseSource')}
            onClick={toggleList}
            readOnly
            value={source || ''}
            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            {...(selectedCompany && {
              style: {
                color: selectedCompany.colors.textColor,
                border: selectedCompany.colors.inputBorder,
              },
            })}
          />
          {sources !== null && (
            <div
              ref={dropdownListRef}
              className={classNames(styles.productList, { [styles.hidden]: !isListDisplayed })}
              id="productList"
              style={{
                position: 'absolute',
                zIndex: 999,
                maxHeight: dropdownMaxHeight,  // Dynamically set max height
                overflowY: 'auto',  // Enable scrolling if content exceeds height
                top: '100%',  // Ensure dropdown stays below the toggler
                left: 0,
                width: '100%',
                backgroundColor: 'white',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                // Conditionally apply selectedCompany styles if a company is selected
                ...(selectedCompany && {
                  color: selectedCompany.colors.textColor,
                  border: selectedCompany.colors.inputBorder,  // Proper border styling
                }),
              }}
            >
              {sources.map((commune) => (
                <div
                  key={commune.name}
                  className={i18n.language === 'fr' ? styles.communeListItem : styles.communeListItemArabic}
                  onClick={() => onItemSelected(commune)}
                >
                  {commune.name}
                </div>
              ))}
            </div>

          )}
          <div className={i18n.language === 'fr' ? styles.arrow : styles.arrowLeft} id="productArrow"
            onClick={toggleList}
            {...(selectedCompany && {
              style: {
                color: selectedCompany.colors.iconColor,
              },
            })}>
            &#9660;
          </div>
        </div>
      </div>
    </div>
  );
};



const types = [
  { name: 'Distributeur' },
  { name: 'Alimentation générale' },
  { name: 'Superette' }
];

export const DropDownType = ({ setType, type }) => {
  const { t, i18n } = useTranslation();
  const [isListDisplayed, setListVisibility] = useState(false);
  const modalContentRef = useRef(null);

  const toggleList = () => {
    console.log('list toggled', isListDisplayed);
    setListVisibility(!isListDisplayed);
  }

  const onItemSelected = (item) => {
    toggleList();
    console.log(item.name);
    setType(item.name);
  }

  const handleClickOutside = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      setListVisibility(false);
    }
  };

  useEffect(() => {
    if (isListDisplayed) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isListDisplayed]);

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {

      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


  return (
    <div ref={modalContentRef} className={classNames(styles.wilayaContainer, styles.productDropdownContainer, styles.width)}>
      <div className={styles.dropdownContainerr}>
        <div className={styles.customDropdownn}>
          <input type="text" id="productDropdown"
            className={classNames("dropdown-input", styles.pointedArea)}
            name="product"
            placeholder={t('chooseType')}
            onClick={toggleList} readOnly value={type || ''}
            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
          />
          {sources !== null && (
            <div className={classNames(styles.productList, { [styles.hidden]: !isListDisplayed })} id="productList">
              {types.map((commune) => (
                <div
                  key={commune.name}
                  className={i18n.language === 'fr' ? styles.communeListItem : styles.communeListItemArabic}
                  onClick={() => onItemSelected(commune)}
                >
                  {commune.name}
                </div>
              ))}
            </div>
          )}
          <div className={i18n.language === 'fr' ? styles.arrow : styles.arrowLeft} id="productArrow" onClick={toggleList}>&#9660;</div>
        </div>
      </div>
    </div>
  );
}


