// companies.js
import jawdaBgImage from "../assets/images/bg.png";
import jawdaLogo from "../assets/images/logo.png";

import swalisBgImage from "../assets/images/bg_swalis.png";
import swalisLogo from "../assets/images/logo_swalis.png";

export const companies = [
    {
        name: "Jawda",
        colors: {
            customerButton: "linear-gradient(to right, #02CED3, #0A8CC6)",
            merchantButton: "linear-gradient(to right, #1c1ccb, #4f4ffc)",
            formGradient: "linear-gradient(to right, #1c1ccb, #6ee2c5)",
            containerShadow:"2px 4px 11px #00d6ff66, -1px -2px 11px #241ccb3b",
            inputBorder:"1px solid #6ee2c5",
            iconColor:"#1c1ccbcf",
            textColor:"#1c1ccb",
            containerTitle:"#1c1ccb",
            typeTextColor:"#1c1ccb",
            secondColor:"#6ee2c5",
            borderTypeColor:"1px solid #6ee2c5",
            selectedTypeTextColor:"white",
            selectedTypeBg:"#f9604f",
        },
        imageBg:jawdaBgImage,
        logo:jawdaLogo
    },
    {
        name: "Swalis",
        colors: {
            customerButton: "linear-gradient(90deg,#d43878,#d62fb2)",
            merchantButton: "linear-gradient(90deg,#b43dc3,#4f4ffc)",
            formGradient: "linear-gradient(90deg,#d43878,#d62fb2)",
            containerShadow:"2px 1px 7px #db11cd69,-1px -2px 11px #d7d7df3b",
            inputBorder:"1px solid #d62fb2",
            iconColor:"black",
            textColor:"black",
            containerTitle:"black",
            typeTextColor:"black",
            secondColor:"#d62fb2",
            borderTypeColor:"1px solid #d62fb2",
            selectedTypeTextColor:"white",
            selectedTypeBg:"#f9604f",
        },
        imageBg:swalisBgImage,
        logo:swalisLogo
    },
];
  