import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import styles from '../css/reclamation.module.scss'; // Adjust the path to your CSS module
import { backendLink } from '../config';

const DistributorCodeModal = ({ isVisible, setVisibility, setUser }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [inputs, setInputs] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const [enabledInputs, setEnabledInputs] = useState([true, false, false, false, false, false]);
    const [error, setError] = useState('');

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace') {
            // If backspace is pressed and current input is empty, move to the previous input
            if (inputs[index] === '' && index > 0) {
                inputRefs.current[index - 1].focus();
            } else {
                // If backspace is pressed and the current input has value, clear it
                const newInputs = [...inputs];
                newInputs[index] = '';
                setInputs(newInputs);
            }
        } else if (event.key === 'Delete') {
            // Delete the current input and move to the previous input
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        } else if (event.key === 'ArrowLeft') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        } else if (event.key === 'ArrowRight') {
            if (index < 5) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleChange = (index, value) => {
        setError('');
        if (value.length > 1) return; // Prevent entering more than one digit

        const newInputs = [...inputs];
        newInputs[index] = value;
        setInputs(newInputs);

        if (value && index < 5) {
            inputRefs.current[index + 1].removeAttribute('disabled');
            inputRefs.current[index + 1].focus();
        } else {
            if (value && index === 5) {
                submitCode(newInputs);
            }
        }
    };

    const goBack = () => {
        navigate('/');
    }

    const submitCode = (newInputs) => {
        const code = newInputs.join(''); // Ensure that 'inputs' is correctly defined
        let formData = new FormData();
        formData.append('code', code);

        fetch(`${backendLink}search_distributor.php`, {
            method: 'POST',
            body: formData,
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data.status_code === 200) {
                    setVisibility(false); // Assuming setVisibility is used to hide some UI elements
                    setUser({
                        id: data.id,
                        nom: data.name,
                        prenom: data.prenom,
                        phone: data.phone,
                        wilaya: data.wilaya,
                        commune: data.commune
                    });
                } else {
                    setError('Code erroné ! Veuillez re-saisir');
                    inputRefs.current[5].focus();
                }
            })
            .catch(error => {
                setError(`Une erreur est survenue: ${error.message}`);
            });
    };

    const hideModal = () => {
        goBack();
      };


    return (
        <div className={`${styles.modalCode} ${!isVisible ? styles.modalHidden : ''}`}>
            <div className={styles.codeBarModal}>
                {/* <div className={styles.codeModalHeader} >
                    <span className={styles.modalClose} onClick={hideModal} style={{ color: 'red', opacity: 1 }}>
                        <FontAwesomeIcon icon={faClose} />
                    </span>
                </div> */}
                <span className={classNames(styles.closeComment, styles.pointedArea)} onClick={hideModal} style={{ color: 'red', opacity: 1 }}>
                    <FontAwesomeIcon icon={faClose} />
                </span>
                <p className={styles.distributorCodeWelcomePhrase}> {t('Bienvenue dans votre section')} </p>
                <p className={styles.distributorCodePhrase} > {t('distributorCodePhrase')} </p>
                <div className={styles.CodeModalContainer}>
                    <div className={styles.divCode} id="div-code">
                        <div className={styles.inputFieldDistributor} style={{ display: isVisible ? 'flex' : 'none' }}>
                            {inputs.map((input, index) => (
                                <input
                                    key={index}
                                    type="number"
                                    pattern="\d"
                                    placeholder="-"
                                    value={input}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    disabled={!enabledInputs[index]}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <p className={styles.codeError} > {error} </p>
                <div className={styles.codeModalFooter} >
                    <p style={{    marginBottom: '0'}} >{t("Vous n'avez pas de compte ?")}</p>
                    {/* <button className={styles.distributorCodeCancel} onClick={goBack}> {t('cancel')} </button> */}
                    {/* <button className={styles.distributorCodeButton} onClick={submitCode} > {t('OK')} </button> */}
                    <button type='button' className={styles.merchantSignupBtn} >
                        <Link className={styles.signupLink} to="/inscription_d">
                            {t('Incrivez vous ici')}
                        </Link>
                    </button>
                </div>
                {/* <Link className={styles.blueUnderline} to="/inscription_d">
                    {t('signup')}
                </Link> */}
            </div>
        </div>
    );
};


export default DistributorCodeModal;
