import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import styles from '../css/reclamation.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


const SuccessToast = ({ text, showToast, setShowToast }) => {
  return (
    <ToastContainer position="bottom-end" className="p-3">
        <Toast 
          onClose={() => setShowToast(false)} 
          show={showToast} 
          delay={3000} 
          autohide
          bg="success" // Set the toast background to success
        >
          <Toast.Body className="text-white">{text}</Toast.Body>
        </Toast>
    </ToastContainer>
  );
};

const FailureToast = ({ text, showToast, setShowToast }) => {
  return (
    <ToastContainer
      position="absolute"
      className="d-flex justify-content-center align-items-center w-100"
      style={{ top: '95%', left: '50%', transform: 'translate(-50%, -50%)' }}
    >
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={1000}
        autohide
        className="bg-danger"  // Set the toast background to error
      >
        <Toast.Body className="text-white">{text}</Toast.Body>
      </Toast>
    </ToastContainer>

  );
};

const PopUpAccountCreation = ({isVisible, code })=>{
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return(
    <div className={`${styles.modal} ${!isVisible ? styles.modalHidden : ''}`}>
      <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className={styles.accountPopupContainer}>
          <p className={styles.phraseCode} > {t('accountCreated')} </p>
          <p className={styles.phraseCode} > {t('yourCodeIs')} </p>
          <p className={styles.codeCommerce}> {code} </p>
          <p className={styles.keepCodePhrase} > {t('keepCodePhrase')} </p>
          
          <div style={{ display: 'flex', gap: '15px', zIndex: 3000 }}>
            <button type='button' onClick={() => navigate('/reclamation_d')} className={`${styles.ouiButton} ${styles.popUpButton}`}> {t('OK')} </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export  {SuccessToast, FailureToast, PopUpAccountCreation};
