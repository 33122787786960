import { companies } from '../components/companies';
import { backendLink } from '../config.js';
import React, { useState, useEffect, useRef, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import xss from 'xss';

import '../css/datePicker.scss';
import styles from '../css/reclamation.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowPointer, faArrowRight, faCircleXmark, faArrowLeft, faBedPulse } from '@fortawesome/free-solid-svg-icons';


import { useForm, Controller, FormProvider, } from 'react-hook-form';
import classNames from 'classnames';
import MetaTags from '../components/MetaTagsReclamation';
import Header from '../components/ReclamationHeader';
import { CodeBarre } from '../components/CodeBarre';

import { DropDownCommercant, DropDownSource } from '../components/DropDown';
import { DatePickerComponent } from '../components/DatePicker';
import { ReclamationTypesDistributor } from '../components/ReclamationTypesDistributor';
import { ImageUploaderC } from '../components/ImageUploader.js';
import { Comment, CommentTextArea } from '../components/Comment';
import { ProductInputCommercant } from '../components/ProductInput';
import ThanksPopup from '../components/ThanksPopUp.js';
import { LanguageSwitcher } from '../components/LanguageSwitcher.js';
import DistributorCodeModal from '../components/DistributorCodeModal.js';
import InstallPopup from '../components/InstalPopup.js';
import InstallPrompt from '../components/InstallPrompt';


const ProductListModal = React.lazy(() => import('../components/CodeBarre').then(module => ({ default: module.ProductListModal })));
const ModalImage = React.lazy(() => import('../components/Modal').then(module => ({ default: module.ModalImage })));
const CommentModal = React.lazy(() => import('../components/Comment').then(module => ({ default: module.CommentModal })));
const PhoneModal = React.lazy(() => import('../components/Phone').then(module => ({ default: module.PhoneModal })));
const NotFoundModal = React.lazy(() => import('../components/NotFoundModal').then(module => ({ default: module.NotFoundModal })));
const UnknownProductModal = React.lazy(() => import('../components/UnknownProductModal').then(module => ({ default: module.UnknownProductModal })));
const LotModal = React.lazy(() => import('../components/LotModal').then(module => ({ default: module.LotModal })));

const ReclamationDistributor = ({ currentLang, setCurrentLang, isPWAInstallable, onInstallClick }) => {
  const [key, setKey] = useState(0);
  const [csrfToken, setCsrfToken] = useState('');
  const [isThanksPopUpVisible, setThanksPopUpVisible] = useState(false);
  const [isModalBarCodeVisible, setModalBarCodeVisible] = useState(false);
  const [isModalCommentVisible, setModalCommentVisible] = useState(false);
  const [isTextareaVisible, setTextareaVisible] = useState(false);
  const [isCommentButtonVisible, setCommentButtonVisible] = useState(true);
  const [isModalPhoneVisible, setModalPhoneVisible] = useState(false);
  const [isInputPhoneVisible, setInputPhoneVisible] = useState(false);
  const [isModalLotVisible, setModalLotVisible] = useState(false);
  const [comment, setComment] = useState('');
  const [phone, setPhone] = useState('');
  const [isSticky, setIsSticky] = useState(false);
  const [isProductInputVisible, setProductInputVisible] = useState(false);
  const [isBarCodeVisible, setBarCodeVisible] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [isProductListModalVisible, setProductListModalVisible] = useState(false);
  const [NotFoundModalVisible, setNotFoundModalVisible] = useState(false);
  const [UnknownProductModalVisible, setUnknownProductModalVisible] = useState(false);
  const codeBarreRef = useRef(null);
  const [isFirstFocus, setIsFirstFocus] = useState(true);
  const inputRef = useRef(null);
  const [resetRadioButton, setResetRadioButton] = useState(false);
  const typesInfoContainerRef = useRef(null);
  const imgCmntContainerRef = useRef(null);
  const locationContainerRef = useRef(null);
  const containerRef = useRef(null);
  const [codeModalVisible, setCodeModalVisible] = useState(false);
  const [user, setUser] = useState({
    id: '',
    nom: '',
    prenom: '',
    phone: '',
    wilaya: '',
    commune: ''
  });
  const [containerProdVisible, setContainerProdVisible] = useState(false);

  useEffect(() => {
    fetch(`${backendLink}search_distributor.php`, {
      method: 'POST',
      credentials: 'include'
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.status_code === 200) {
          setCodeModalVisible(false); // Assuming setVisibility is used to hide some UI elements
          // setUser({
          //   id: data.id,
          //   nom: data.name,
          //   prenom: data.prenom,
          //   phone: data.phone,
          //   wilaya: data.wilaya,
          //   commune: data.commune
          // });
        } else {
          setCodeModalVisible(true);
        }
      })
      .catch(error => {
        console.log(`Une erreur est survenue: ${error.message}`);
      });
  }, []);




  const scrollToTypeInfoContainer = () => {
    if (typesInfoContainerRef.current) {
      // Use scrollIntoView to automatically scroll to the element
      typesInfoContainerRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scroll animation
        block: 'end',
      });
    }
  };

  // const scrollToImgCmntContainer = () => {
  //   // if (imgCmntContainerRef.current) {
  //   //   // First, scroll it into view
  //   //   imgCmntContainerRef.current.scrollIntoView({
  //   //     behavior: 'smooth',
  //   //     block: 'start',
  //   //   });
  //   // }

  //   if (imgCmntContainerRef.current) {
  //     const element = imgCmntContainerRef.current;
  //     const elementRect = element.getBoundingClientRect();
  //     const absoluteElementTop = elementRect.top + window.pageYOffset;
  //     const middle = absoluteElementTop - (window.innerHeight / 2);
  //     window.scrollTo(0, middle);
  //   }
  // }

  const scrollToImgCmntContainer = () => {
    if (imgCmntContainerRef.current) {
      // Use scrollIntoView to automatically scroll to the element
      imgCmntContainerRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scroll animation
        block: 'center',    // Center the element in the view
      });
    }
  };

  const scrollToLocationContainer = () => {
    if (locationContainerRef.current) {
      // First, scroll it into view
      locationContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    const digits = value.replace(/\D/g, '');

    // Format the number with spaces after every two digits
    const formatted = digits.match(/.{1,2}/g)?.join(' ') || '';

    return formatted.trim();
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate('/');
  }

  useEffect(() => {
    fetch(`${backendLink}get_csrf_token.php`, {
      method: 'GET',
      credentials: 'include'
    })
      // .then(response => {
      //   if (!response.ok) {
      //     throw new Error('Network response was not ok');
      //   }
      //   return response.json();
      // })
      .then(response => {
        if (response.status === 403) {
            // If status is 403, call goBack() and return to stop further execution
            goBack();
            return;  // Exit the promise chain
        }
        return response.json();  // Continue processing if status is not 403
      })
      .then(data => {
        // setCsrfToken(data.csrf_token);
      })
      .catch(error => {
        console.error('Error fetching CSRF token:', error);
      });
  }, []);

  const resetFormAndState = () => {
    // window.location.reload();
    navigate(0);
  };

  /** handleFocus is defined to handle first time focus on n_lot input to show the user
   * an image (in lotModal) to know where he can find batch number in product packaging
  */
  const handleFocus = (e) => {
    if (isFirstFocus) {
      setIsFirstFocus(false);
      setModalLotVisible(true);
      e.target.blur();
    }
  };

  /** When LotModal is hidden cursor must be focused on Lot input */
  const focusLotInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  /** When the user enters the last 6 numbers and they are not found the popup indicating it 
   * is displayed and when it's closed cursor must be focused again in codebarre input
   */
  const handleModalNotFoundClose = () => {
    setNotFoundModalVisible(false);
    if (codeBarreRef.current) {
      codeBarreRef.current.focusFirstInput();
    }
  };

  /** Define default inputs' values of form */
  const methods = useForm({
    defaultValues: {
      selectedTypes: [],
      location: { wilaya: '', commune: '' },
      product: [],
      n_lot: '',
      selectedDate: null,
      comment: '',
      phone: '',
      compressedFiles: [],
      source: '',
      tous: 'false',
    },
  });



  /**
   * Destructuring the methods object provided by react-hook-form.
   * - handleSubmit: Function to handle form submission.
   * - control: Object to control form elements and integrate with React Hook Form.
   * - setValue: Function to set the value of a specific form field.
   * - getValues: Function to get the current values of all form fields.
   *
   * These functions and objects are used to manage the form's state, validation, and submission in a declarative manner.
  */
  const { handleSubmit, control, setValue, getValues } = methods;

  // Load data from localStorage on component mount
  // useEffect(() => {
  //   const savedData = JSON.parse(localStorage.getItem("formDataC"));
  //   if (savedData) {
  //     let types;
  //     if ("selectedTypes" in savedData && savedData.selectedTypes) {
  //       types = savedData.selectedTypes
  //     }

  //     Object.keys(savedData).forEach((key) => {
  //       if (key === "selectedDate" && savedData[key]) {
  //         setValue(key, new Date(savedData[key])); // Convert to Date object
  //       } else {
  //         setValue(key, savedData[key]);
  //       }
  //     });

  //     if ("product" in savedData && savedData.product) {
  //       // setProductInputVisible(true);
  //       // setBarCodeVisible(false);
  //       setProductVisibility(new Array(savedData.product.length).fill(true));

  //       if(savedData.product.length===3){
  //         // setProductVisibility(false);
  //         setBarCodeVisible(false);
  //       }

  //     }

  //     // Delay the execution of the "types" block
  //     if (types) {
  //       setTimeout(() => {
  //         console.log(types);
  //         setValue("selectedTypes", types);
  //       }, 3000); // Delay of 3000 milliseconds (3 seconds)
  //     }

  //     // if ("product" in savedData && savedData.product) {
  //     //   console.log(savedData.product);
  //     //   setValue("product", savedData.product);
  //     //   setProductInputVisible(true);
  //     //   if(types){
  //     //     console.log(types);
  //     //     setValue("selectedTypes", types);
  //     //   }
  //     // }

  //     if ("phone" in savedData && savedData.phone) {
  //       setValue("phone", savedData.phone);
  //     }
  //     if ("location" in savedData && savedData.location) {
  //       setValue("location", savedData.location);
  //     }
  //   }
  // }, [setValue]);

  // Save form data to localStorage on change
  useEffect(() => {
    const subscription = methods.watch((value) => {
      localStorage.setItem("formDataC", JSON.stringify(value));
    });
    return () => subscription.unsubscribe();
  }, [methods]);

  /** called on submitting form handleSubmit(onSubmit) */
  const onSubmit = async (data, event) => {
    event.preventDefault();
    setThanksPopUpVisible(true);
    try {
      const formDataC = new FormData();
      // formDataC.append('name', user.nom ?? '');
      formDataC.append('nom_produit', data.product);
      formDataC.append('n_lot', data.n_lot);
      if (data.selectedDate !== null) {
        formDataC.append('date_fabrication', data.selectedDate.toISOString());
      } else {
        formDataC.append('date_fabrication', '0000-00-00');
      }
      formDataC.append('type_reclamation', data.selectedTypes);
      formDataC.append('description', data.comment);
      // formDataC.append('region', data.location.wilaya);
      // formDataC.append('commune', data.location.commune);
      // formDataC.append('region', user.wilaya);
      // formDataC.append('commune', user.commune);
      // formDataC.append('phone_num', user.phone);
      formDataC.append('envoyer', true);
      formDataC.append('envoyerD', true);
      // formDataC.append('user', user.id);
      formDataC.append('tous', data.tous);
      formDataC.append('source', data.source);
      // formDataC.append('_csrf', csrfToken);

      /** Retrieve compressed images and store them in formDataC */
      // if (data.compressedFiles.length > 0) {
      //   data.compressedFiles.forEach((file) => {
      //     formDataC.append('imagesAll[]', file);
      //   });
      // }

      const base64ToBlob = (base64, mimeType) => {
        try {
          // Ensure the base64 string is properly formatted
          const base64Data = base64.includes(',') ? base64.split(',')[1] : base64;

          // Decode Base64
          const byteCharacters = atob(base64Data);

          // Create byte numbers array
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          // Convert byte numbers to a byte array and create a Blob
          const byteArray = new Uint8Array(byteNumbers);
          return new Blob([byteArray], { type: mimeType });
        } catch (error) {
          console.error("Failed to convert base64 to Blob:", error);
          // throw error; // Re-throw to handle it elsewhere if needed
        }
      };


      /** Retrieve compressed images and store them in formDataC */
      if (data.compressedFiles.length > 0) {
        data.compressedFiles.forEach((fileData) => {

          const blob = base64ToBlob(fileData.base64, fileData.type);
          const file = new File([blob], fileData.name, {
            type: fileData.type,
            lastModified: fileData.lastModified,
          })
          formDataC.append('imagesAll[]', file);
        });
      }



      // Log the form data
      for (let [key, value] of formDataC.entries()) {
        console.log(`${key}: ${value}`);
      }

      localStorage.setItem('formDataC', null);

      const response = await fetch(`${backendLink}back.php`, {
        method: 'POST',
        body: formDataC,
        credentials: 'include' // Include cookies in the request for CSRF protection
      });

      if (response.status === 403) {
        // Handle the 403 Forbidden response
        alert("Un échec est survenu ! Veuillez réessayer d'envoyer votre réclamation. Merci !");
        goBack();
        return;
        // You can also redirect the user or take any other action
      }
      

    } catch (error) {
      console.error('Error:', error);
    }
  };

  /** When window/form is scrolled the navbar containing logo 
   * => we need to add class sticky (for white background) otherwise navbar should not have the class sticky 
   * scroll is detected with useEffect react method
  */
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const SCROLL_THRESHOLD = 5;

  // const handleScroll = (e) => {
  //   if (e.target.scrollTop > SCROLL_THRESHOLD) {
  //     setIsSticky(true);
  //   } else {
  //     setIsSticky(false);
  //   }
  // };

  const handleScroll = (e) => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > SCROLL_THRESHOLD || e.target.scrollTop > SCROLL_THRESHOLD) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const hideModalPhone = () => {
    setModalPhoneVisible(false);
    setResetRadioButton(true);
  };

  const showModalComment = () => {
    setModalCommentVisible(true);
  };

  const hideModalComment = () => {
    setModalCommentVisible(false);
  };

  const showModalImage = () => {
    setModalBarCodeVisible(true);
  };

  const hideModalImage = () => {
    setModalBarCodeVisible(false);
  };

  const handleCancel = () => {
    window.location.href = 'index.php';
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(currentLang);
  }, [currentLang, i18n]);

  const [productVisibility, setProductVisibility] = useState(
    getValues('product').map(() => true)  // Initialize visibility state for each product
  );

  const toggleVisibility = (index) => {
    const updatedVisibility = [...productVisibility];
    updatedVisibility[index] = !updatedVisibility[index];  // Toggle visibility for the specific product
    setProductVisibility(updatedVisibility);
  };


  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {

      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


  return (
    <div className={classNames(styles.mainContainer, { [styles.notScrollable]: isModalCommentVisible })} onScroll={handleScroll} ref={containerRef}>
      <MetaTags />
      <Header isSticky={isSticky || isModalCommentVisible} currentLang={currentLang} setCurrentLang={setCurrentLang} />
      {/* <LanguageSwitcher currentLang={currentLang} setCurrentLang={setCurrentLang} /> */}
      {/** FormProvider is used to share form state with all nested components */}
      <FormProvider {...methods}>
        <div className={classNames(styles.container)}>
          <h1 className={styles.titleForm}
            {...(selectedCompany && {
              style: {
                color: selectedCompany.colors.textColor,
              },
            })}> {t('titleFormDistributor')} </h1>
          <form className={styles.form} id="myform" onSubmit={handleSubmit(onSubmit)}>
            <div className={classNames(styles.infoContainer, styles.secondContainer)}
              ref={typesInfoContainerRef}
              {...(selectedCompany && {
                style: {
                  boxShadow: selectedCompany.colors.containerShadow,
                },
              })}>
              <h4 className={styles.infoContainerTitle}
                {...(selectedCompany && {
                  style: {
                    color: selectedCompany.colors.containerTitle,
                  },
                })}> {t('typeQuestion')} </h4>
              <p className={styles.secondaryText}>({t('select')})</p>
              <Controller
                name="selectedTypes"
                control={control}
                render={({ field }) => (
                  <ReclamationTypesDistributor selectedTypes={field.value}
                    setSelectedTypes={field.onChange}
                    onScroll={scrollToTypeInfoContainer}
                    typesInfoContainerRef={typesInfoContainerRef}
                    containerRef={containerRef}
                    product={getValues('product')}
                    setContainerProdVisible={setContainerProdVisible}
                    setProduct={(product) => setValue('product', product)} />
                )}
              />
            </div>

            {containerProdVisible &&
              <div className={classNames(styles.infoContainer, styles.onTopContainer)}
                {...(selectedCompany && {
                  style: {
                    boxShadow: selectedCompany.colors.containerShadow,
                  },
                })}>
                <h4
                  {...(selectedCompany && {
                    style: {
                      color: selectedCompany.colors.containerTitle,
                    },
                  })}> {t('productQuestion')} </h4>
                {/** passing getValues function as prop to share form value of product */}
                {/* {getValues('product') && getValues('product').map((productItem, index) => (
                <ProductInput
                  key={index}  // Provide a unique key for each item in the array
                  isVisible={isProductInputVisible}
                  setIsVisible={setProductInputVisible}
                  product={productItem}  // Pass the current product from the array
                  setProductInputsVisibility={setBarCodeVisible}
                  setProduct={(updatedProduct) => {
                    const updatedProducts = [...getValues('product')];
                    updatedProducts[index] = updatedProduct;  // Update the specific product in the array
                    setValue('product', updatedProducts);  // Update the entire product array with the modified product
                  }}
                />
              ))} */}
                {/* {getValues('product') && getValues('product').map((productItem, index) => (
                <ProductInputCommercant
                  key={index}  // Provide a unique key for each item in the array
                  isVisible={productVisibility[index]}  // Control visibility for each product individually
                  setIsVisible={() => toggleVisibility(index)}  // Toggle visibility for the specific product
                  product={productItem}  // Pass the current product from the array
                  setProductInputsVisibility={setBarCodeVisible}
                  removeProduct={() => {
                    const updatedProducts = getValues('product').filter((_, i) => i !== index);  // Remove product at the specified index
                    console.log(updatedProducts)
                    setValue('product', updatedProducts);  // Update the product array with the removed product
                  }}
                />
              ))} */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '17px' }} >

                  {getValues('product') && getValues('product').map((productItem, index) => (
                    <ProductInputCommercant
                      key={index}  // Provide a unique key for each item in the array
                      isVisible={productVisibility[index]}  // Control visibility for each product individually
                      setIsVisible={() => toggleVisibility(index)}  // Toggle visibility for the specific product
                      product={productItem}  // Pass the current product from the array
                      setProductInputsVisibility={setBarCodeVisible}
                      removeProduct={() => {
                        // Remove product at the specified index
                        const updatedProducts = getValues('product').filter((_, i) => i !== index);

                        // Update the product array in the form state
                        setValue('product', updatedProducts);

                        // Update the product visibility array to remove the corresponding product visibility
                        const updatedVisibility = productVisibility.filter((_, i) => i !== index);

                        // Update visibility and ensure re-render
                        setProductVisibility(updatedVisibility);

                        console.log(updatedProducts);  // Log the updated product list to ensure correctness
                      }}
                    />
                  ))}
                </div>





                {/* <ProductInput
                isVisible={isProductInputVisible}
                setIsVisible={setProductInputVisible}
                product={getValues('product')}
                setProductInputsVisibility={setBarCodeVisible}
                setProduct={(product) => setValue('product', product)}
              /> */}
                {/* <div className={styles.codeBarContainer} style={{ display: isBarCodeVisible ? 'flex' : 'none' }}>
                <p className={classNames(styles.brCodePar, i18n.language === 'ar' ? styles.brCodeParAr : null)}
                  dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} // Set direction based on current language
                >
                  {t('barCodeInstruction')}
                  <FontAwesomeIcon
                    icon={i18n.language === 'fr' ? faArrowRight : faArrowLeft}
                    className={styles.arrowEg}
                    style={{ marginLeft: i18n.language === 'fr' ? '8px' : '0', marginRight: i18n.language === 'ar' ? '8px' : '0', verticalAlign: 'middle' }} // Ensure vertical alignment
                  />
                  <button type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      showModalImage();
                    }}
                    className={styles.codeBarresButton}
                    id="codeBarresButton"
                  >
                    {t('seeEg')} <FontAwesomeIcon icon={faArrowPointer} />
                  </button>
                </p>
              </div> */}
                {/* <div className={styles.divider} style={{ display: isBarCodeVisible ? 'flex' : 'none' }}>
                {t('Option1')}
              </div> */}
                {/** Passing setValue function as a prop to set the FORM value of product
              and ref prop to be able to control the focus on first input of CodeBarre */}
                {/* <CodeBarre
                setSelectedProducts={setSelectedProducts}
                setProductListModalVisible={setProductListModalVisible}
                setProduct={(product) => setValue('product', product)}
                setProductVisibility={setProductInputVisible}
                isVisible={isBarCodeVisible}
                setVisibility={setBarCodeVisible}
                setNotFoundModalVisible={setNotFoundModalVisible}
                setUnknownProductModalVisible={setUnknownProductModalVisible}
                ref={codeBarreRef}
              /> */}
                <div id="productInfos" className={styles.productInfos}>
                  <div id="productinputContainer" className={styles.productInputContainer}>
                    <div>
                      <input
                        id="productName"
                        name="nom_produit"
                        placeholder="Nom du produit"
                        style={{ width: '100%' }}
                        readOnly
                        onClick={(event) => event.stopPropagation()}
                        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                      />
                      <button
                        type="button"
                        id="hideProductInputButton"
                        onClick={(event) => {
                          event.stopPropagation();
                          hideProductInput(event);
                        }}
                      >
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </button>
                    </div>
                  </div>
                  <label id="labelBarCode" style={{ display: 'none', margin: 'auto' }}>
                    {t('barCode')}
                  </label>
                  <input type="hidden" id="productLot" name="codebarre" style={{ width: '100%' }} readOnly />
                  <input id="codeInputMobile" type="hidden" />
                  <input id="nameInputMobile" type="hidden" />
                </div>
                {/* <div className={styles.divider} style={{ display: isBarCodeVisible ? 'flex' : 'none' }}>
                {t('Option2')}
              </div> */}
                {/* <DropDownCommercant
                placeholder={t('chooseProduct')}
                productVisibility={productVisibility}
                setProductVisibility={setProductVisibility}
                setProduct={(product) => setValue('product', product)}
                product={getValues('product')}
                isVisible={isBarCodeVisible}
                setProductInputsVisibility={setBarCodeVisible}
              /> */}
                <DropDownCommercant
                  placeholder={t('chooseProduct')}
                  productVisibility={productVisibility}  // Updated visibility array
                  setProductVisibility={setProductVisibility}  // Function to update visibility
                  setProduct={(product) => setValue('product', product)}  // Update product in form state
                  product={getValues('product')}  // Ensure this always fetches the updated product array
                  isVisible={isBarCodeVisible}
                  setProductInputsVisibility={setBarCodeVisible}
                  tous={getValues('tous')}
                  setTous={(value) => setValue('tous', value)}
                />
                {/* <div className={styles.containerLot}  >
                <div id="numLotSection" className={styles.numLotSection}>
                  <Controller
                    name="n_lot"
                    control={control}
                    render={({ field }) => (
                      <input
                        onFocus={handleFocus}
                        ref={(e) => {
                          field.ref(e);
                          inputRef.current = e;
                        }}
                        type="text"
                        placeholder={t('lotPlaceholder')}
                        onInput={(event) => limitInputLength(event.target, 10)}
                        maxLength="10"
                        style={{ margin: 0, width: '100%' }}
                        {...field}
                        onChange={(e) => {
                          // Sanitize the input value using xss
                          const sanitizedValue = xss(e.target.value);
                          field.onChange(sanitizedValue);
                        }}
                        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                      />
                    )}
                  />
                </div>
                <div id="lot" className={styles.imagelot} style={{ display: 'none' }}></div>
              </div> */}
                {/* <Controller
                name="selectedDate"
                control={control}
                render={({ field }) => (
                  <DatePickerComponent selectedDate={field.value} setSelectedDate={field.onChange} onBlur={scrollToTypeInfoContainer} />
                )}
              /> */}
              </div>
            }

            <div className={classNames(styles.infoContainer)} ref={imgCmntContainerRef}
              {...(selectedCompany && {
                style: {
                  boxShadow: selectedCompany.colors.containerShadow,
                },
              })}>
              <h4
                {...(selectedCompany && {
                  style: {
                    color: selectedCompany.colors.containerTitle,
                  },
                })}> {t('addImage')} </h4>
              <Controller
                name="compressedFiles"
                control={control}
                render={({ field }) => (
                  <ImageUploaderC setValue={setValue}
                    getValues={getValues}
                    onScroll={scrollToImgCmntContainer} />
                )}
              />
              {/** Popup showed on click on Ajouter un commentaire, user writes its comments in and saves it */}
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <>
                    <Comment showModalComment={showModalComment}
                      isButtonVisible={isCommentButtonVisible}
                      onScroll={scrollToImgCmntContainer} />
                    <CommentTextArea isTextareaVisible={isTextareaVisible} comment={field.value} showModalComment={showModalComment} />
                  </>
                )}
              />
            </div>

            <div className={classNames(styles.infoContainer, styles.onTopContainer)}
              ref={locationContainerRef}
              {...(selectedCompany && {
                style: {
                  boxShadow: selectedCompany.colors.containerShadow,
                },
              })}>
              <h4
                {...(selectedCompany && {
                  style: {
                    color: selectedCompany.colors.containerTitle,
                  },
                })}> {t('fromWhereDistributor')} </h4>
              {/* <Controller
                name="nomC"
                control={control}
                rules={{
                  pattern: {
                    value: /^[A-Za-z\u0600-\u06FF\s]+$/,
                    message: "Seulement les alphabets sont permis",
                  },
                  maxLength: {
                    value: 20,
                    message: "Maximum length is 20 characters",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <input
                      type="text"
                      placeholder={t('namePlaceholder')}
                      onInput={(event) => limitInputLength(event.target, 20)}
                      maxLength="20"
                      {...field}
                      onChange={(e) => {
                        const sanitizedValue = xss(e.target.value);
                        // Only keep alphabetic characters
                        const alphabeticValue = sanitizedValue.replace(/[^A-Za-z\u0600-\u06FF\s]/g, '');
                        field.onChange(alphabeticValue);
                      }}
                      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                      lang={i18n.language === 'ar' ? 'ar' : 'fr'}  // Set the language for keyboard input
                      inputMode={i18n.language === 'ar' ? 'text' : 'latin'}  // Suggest Arabic or Latin keyboard
                      className={styles.nameInput}
                    />
                    {error && <p>{error.message}</p>}
                  </>
                )}
              /> */}
              <Controller
                name="source"
                control={control}
                render={({ field }) => (
                  <DropDownSource
                    source={field.value}
                    setSource={field.onChange}
                    onScroll={scrollToLocationContainer}
                  />
                )}
              />

            </div>
            {/* <div className={styles.infoContainer}>
              <h4> {t('PersonalInfo')} </h4>
              <Controller
                name="nom"
                control={control}
                rules={{
                  pattern: {
                    value: /^[A-Za-z\u0600-\u06FF\s]+$/,
                    message: "Seulement les alphabets sont permis",
                  },
                  maxLength: {
                    value: 20,
                    message: "Maximum length is 20 characters",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <input
                      type="text"
                      placeholder={t('namePlaceholder')}
                      onInput={(event) => limitInputLength(event.target, 20)}
                      maxLength="20"
                      {...field}
                      onChange={(e) => {
                        const sanitizedValue = xss(e.target.value);
                        // Only keep alphabetic characters
                        const alphabeticValue = sanitizedValue.replace(/[^A-Za-z\u0600-\u06FF\s]/g, '');
                        field.onChange(alphabeticValue);
                      }}
                      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                      lang={i18n.language === 'ar' ? 'ar' : 'fr'}  // Set the language for keyboard input
                      inputMode={i18n.language === 'ar' ? 'text' : 'latin'}  // Suggest Arabic or Latin keyboard
                      className={styles.nameInput}
                    />
                    {error && <p>{error.message}</p>}
                  </>
                )}
              />

              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <input
                  type='tel'
                    placeholder={t('phonePlaceholder')}
                    onInput={(event) => limitInputLength(event.target, 14)}
                    maxLength="14"
                    style={{ marginBottom: "10px", width: '100%', direction: 'ltr' }}
                    {...field}
                    value={formatPhoneNumber(field.value || '')}
                    onChange={(e) => {
                      // Sanitize the input value using xss
                      const sanitizedValue = xss(e.target.value);
                      field.onChange(sanitizedValue);
                    }}
                    dir='ltr'
                    className={i18n.language === 'ar' ? styles.phoneInputWrapperAr : ''}
                  // dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                  />
                )}
              />
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <DropDownLocation
                    wilaya={field.value?.wilaya || ''}
                    commune={field.value?.commune || ''}
                    setWilaya={(value) => setValue('location', { ...getValues('location'), wilaya: value })}
                    setCommune={(value) => setValue('location', { ...getValues('location'), commune: value })}
                    onScroll={scrollToLocationContainer}
                  />
                )}
              />
            </div> */}
            <div className={styles.navButtons}>
              <button id="Annuler" className={styles.annuler} type="button" onClick={resetFormAndState}>
                {t('cancel')}
              </button>
              <button className={styles.envoyer} type="submit" name="envoyer" id="Envoyer"
                {...(selectedCompany && {
                  style: {
                    background: selectedCompany.colors.formGradient,
                  },
                })}>
                {t('send')}
              </button>
            </div>
          </form>
        </div>

        {/** When user enters last 6 numbers of bar code and many products their barcode 
        ending with these numbers are found a modal with a list of their names is displayed 
        and user can select a one.  */}
        <InstallPrompt onInstallClick={onInstallClick} t={t} isPWAInstallable={isPWAInstallable} />

        <Suspense>
          {/* <InstallPopup isPWAInstallable={isPWAInstallable && !codeModalVisible} onInstallClick={onInstallClick} /> */}
          <ProductListModal
            products={selectedProducts}
            isVisible={isProductListModalVisible}
            setIsVisible={setProductListModalVisible}
            setProduct={(product) => setValue('product', product)}
            setProductVisibility={setProductInputVisible}
            setProductInputsVisibility={setBarCodeVisible}
          />
          {/** On choosing OUI radio button popup is displayed to enter phone number */}
          <PhoneModal
            isVisible={isModalPhoneVisible}
            hideModal={hideModalPhone}
            setPhone={setPhone}
            phoneInputVisivlity={setInputPhoneVisible}
            setValue={setValue}
            resetRadioButton={() => setResetRadioButton(false)}
          />

          {/** On clicking on Ajouter un commentaire this popup to enter comment is displayed */}
          <CommentModal
            hideModalComment={hideModalComment}
            isVisible={isModalCommentVisible}
            setTextareaVisible={setTextareaVisible}
            setTextAreaText={setComment}
            isTextareaVisible={isTextareaVisible}
            setButtonVisibility={setCommentButtonVisible}
            comment={comment}
            setValue={setValue}
          />

          {/** On clicking on button Voir exemple of code bar popup ModalImage is shown 
          with image of bar code as an illusta-ration for user */}
          <ModalImage
            hideModalImage={hideModalImage}
            isVisible={isModalBarCodeVisible}
          />

          {/** When user enters last 6 numbers of bar code and no product corresponds this modal is shown */}
          <NotFoundModal
            isVisible={NotFoundModalVisible}
            setNotFoundModalVisible={setNotFoundModalVisible}
            onClose={handleModalNotFoundClose}
          />

          {/** If user enters an unknown same bar code for 2nd time this popup is displayed so he can add product name */}
          <UnknownProductModal
            isVisible={UnknownProductModalVisible}
            setProduct={(product) => setValue('product', product)}
            setVisibility={setUnknownProductModalVisible}
            setProductInputsVisibility={setBarCodeVisible}
            setProductVisibility={setProductInputVisible}
          />

          {/** On first time focusing on input of LotModal this popup is displayed to illustrate where
          consumer can find LotModal */}
          <LotModal
            isVisible={isModalLotVisible}
            setIsVisible={setModalLotVisible}
            focusLotInput={focusLotInput}
          />

          <ThanksPopup
            isVisible={isThanksPopUpVisible}
            updatePage={resetFormAndState}
            isPWAInstallable={isPWAInstallable}
            onInstallClick={onInstallClick}
          />
          <DistributorCodeModal setUser={setUser} isVisible={codeModalVisible} setVisibility={setCodeModalVisible} />


        </Suspense>
      </FormProvider>
    </div>
  );
};

function hideProductInput() { }

function limitInputLength(target, max) {
  // console.log(target.value, target.value.length, target);
  console.log(target.value.trim(0, 10));
  if (target.value.length > max) {
    target.value = target.value.substring(0, 10);
  }
}

export default ReclamationDistributor;
