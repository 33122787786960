import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from '../css/landing.module.scss'; // Update with your actual CSS file path
import { companies } from './companies';

const InstallPrompt = ({ onInstallClick, isPWAInstallable, alertsOpen }) => {
  const { t, i18n } = useTranslation();
  const [isFirstAppearance, setIsFirstAppearance] = useState(false);
  const [isVisible, setIsVisible] = useState(isPWAInstallable);

  // const [isVisible, setIsVisible] = useState(false);

  // // useEffect(() => {
  // //   // Only start the animation once alertsOpen becomes false
  // //   if (!alertsOpen && isPWAInstallable) {
  // //     // Show the container, but apply the slide-in animation after a brief delay
  // //     setIsVisible(true);
  // //     setIsFirstAppearance(true);

  // //     // setTimeout(() => {
  // //     //   setIsFirstAppearance(true);
  // //     // }, 100); 
  // //   }
  // // }, [alertsOpen, isPWAInstallable]); // Re-run when alertsOpen or isPWAInstallable changes

  // const canceledInstallation = localStorage.getItem('canceledInstallation') === 'true';
  // useEffect(() => {
  //   // Only start the animation once alertsOpen becomes false
    
  //   if (canceledInstallation && isPWAInstallable) {
  //     // Show the container, but apply the slide-in animation after a brief delay
  //     setIsVisible(true);
  //     setIsFirstAppearance(true);

  //     // setTimeout(() => {
  //     //   setIsFirstAppearance(true);
  //     // }, 100); 
  //   }
  // }, [canceledInstallation, isPWAInstallable]); // Re-run when alertsOpen or isPWAInstallable changes

  // Switch company 
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {
      
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts




  return (
    <div
      className={classNames(
        styles.install_cont,
        { [styles.install_cont_ar]: i18n.language === 'ar' },
        { [styles.hidden]: !isVisible }, // Hidden until isVisible becomes true
        { [styles.slide_in]: isFirstAppearance } // Apply slide-in animation when isFirstAppearance is true
      )}
      id="install_cont"
    >
      <p className={styles.installContPar}>{t('installQuestion')}</p>
      <button
        className={styles.installButton}
        onClick={onInstallClick}
        id="installButton"
        {...(selectedCompany && {
          style: {
            backgroundColor: selectedCompany.colors.secondColor,
          },
        })}
      >
        {t('oui')}
      </button>
    </div>
  );
};

export default InstallPrompt;
