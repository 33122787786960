import React, { useState, useRef, useEffect } from 'react';
import ImageUploading from 'react-images-uploading';
import Compressor from 'compressorjs';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import sanitizeFilename from 'sanitize-filename';

import styles from '../css/reclamation.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons';
import { companies } from './companies';

export const LotImageUploader = ({ setValue, getValues, onScroll }) => {
  const [images, setImages] = useState([]);
  const [isCompressing, setIsCompressing] = useState(false);
  const { t, i18n } = useTranslation();
  const compressCancelFlags = useRef({});
  const maxNumber = 1; // Limit to 1 image

  // Load images from localStorage when component mounts
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('formData'));
    if (savedData) {
      const compressedFiles = savedData.compressedLot;
      if (compressedFiles && compressedFiles.length !== 0) {
        const loadedImages = compressedFiles.map((fileData, index) => ({
          data_url: fileData.base64, // Use base64 data
          file: new File([], fileData.name, {
            type: fileData.type,
            lastModified: fileData.lastModified,
          }),
          id: `${fileData.name}_${index}`,
        }));
        setImages(loadedImages);
      }
    }
  }, []);

  // Save form data to localStorage on change
  useEffect(() => {
    if (images.length > 0) {
      // Prepare images with base64 data for saving
      const compressedLot = images.map((image) => ({
        base64: image.data_url, // Save base64 data
        name: image.file.name,
        type: image.file.type,
        lastModified: image.file.lastModified,
      }));

      // Merge the new compressedLot with the existing formData
      const savedData = JSON.parse(localStorage.getItem('formData')) || {};
      const updatedData = {
        ...savedData,
        compressedLot, // Update only compressedLot, keep other data
      };

      localStorage.setItem('formData', JSON.stringify(updatedData));
    }
  }, [images]);

  const displayAlert = (message) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const checkImages = (imagesList) => {
    const validImages = [];
    imagesList.forEach((image) => {
      const safeFilename = sanitizeFilename(image.file.name);
      if (!image.file.type.startsWith('image/')) {
        displayAlert("Le fichier sélectionné n'est pas valide");
      } else if (safeFilename !== image.file.name) {
        displayAlert('Le nom du fichier contient des caractères non valides');
      } else {
        validImages.push(image);
      }
    });
    return validImages;
  };

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 800,
        maxHeight: 800,
        mimeType: 'image/jpeg',
        success: (compressedFile) => resolve(compressedFile),
        error: (error) => reject(error),
      });
    });
  };

  const handleImageChange = async (imageList) => {
    setIsCompressing(true);
  
    // Find the newly added images by comparing with the current images in state
    const newImages = imageList.filter(
      (image) => !images.some((img) => img.file.name === image.file.name)
    );
  
    // Validate and compress the new images
    const base64Files = await Promise.all(
      newImages.map(async (image) => {
        if (image.compressed) {
          // If the image is already compressed, skip compression
          return image;
        }
  
        try {
          // Compress the image and convert it to base64
          const compressedFile = await compressImage(image.file);
  
          // Create a base64 representation of the compressed image
          const reader = new FileReader();
          return new Promise((resolve, reject) => {
            reader.onload = () => {
              resolve({
                base64: reader.result,  // Base64 data
                file: compressedFile,   // Compressed file object
                compressed: true,
              });
            };
            reader.onerror = () => reject('Error reading file');
            reader.readAsDataURL(compressedFile); // Convert to base64
          });
        } catch (error) {
          console.error('Error during compression:', error);
          throw error;
        }
      })
    );
  
    // Add only the newly processed images to the state
    setImages((prevImages) => {
      const updatedImages = [
        ...prevImages, // Existing images
        ...base64Files.map((fileData, index) => ({
          data_url: fileData.base64,  // Base64 for preview
          file: fileData.file,        // Compressed file object
          id: `${fileData.file.name}_${index}`,  // Unique ID
          compressed: true,
        })),
      ];
  
      // Update the form value with the compressed files (base64 and metadata)
      setValue('compressedLot', [
        ...updatedImages.map(fileData => ({
          base64: fileData.data_url,
          name: fileData.file.name,
          type: fileData.file.type,
          lastModified: fileData.file.lastModified,
        }))
      ]);
  
      return updatedImages;
    });
  
    setIsCompressing(false);
  };
  

  const removeImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    const currentCompressedLot = getValues('compressedLot') || [];
    const updatedCompressedLot = currentCompressedLot.filter((_, i) => i !== index);
    setValue('compressedLot', updatedCompressedLot);
  };

  // Load selected company from localStorage
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem('selectedCompany');

  useEffect(() => {
    const loadSelectedCompany = () => {
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    loadSelectedCompany();
    window.addEventListener('storage', loadSelectedCompany);

    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]);

  return (
    <div className={classNames(i18n.language === 'fr' ? styles.imagesFieldLot : styles.imagesFieldLotArabic)}>
      <ImageUploading
        multiple
        value={images}
        onChange={handleImageChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpload, onImageRemove }) => (
          <div className={styles.imagePreviews}>
            {imageList.map((image, index) => (
              <div key={index} className={styles.buttonContainer}
                {...(selectedCompany && {
                  style: {
                    border: selectedCompany.colors.inputBorder,
                  },
                })}
              >
                <div className={styles.imageButton}>
                  <img src={image.data_url} alt={`Preview ${index}`} className={styles.previewImage} />
                  {isCompressing && (
                    <div className={styles.loadingOverlay}>
                      <FontAwesomeIcon icon={faSpinner} className={styles.spinnerIcon} spin />
                    </div>
                  )}
                  <span className={styles.removeBtn} onClick={() => removeImage(index)}>
                    <FontAwesomeIcon icon={faXmark} />
                  </span>
                </div>
              </div>
            ))}
            {imageList.length < maxNumber && (
              <button
                type="button"
                className={styles.addImageButton}
                onClick={onImageUpload}
                disabled={isCompressing}
                {...(selectedCompany && {
                  style: {
                    border: selectedCompany.colors.inputBorder,
                  },
                })}
              >
                <FontAwesomeIcon icon={faCamera} className={styles.addImageIcon}
                  {...(selectedCompany && {
                    style: {
                      color: selectedCompany.colors.iconColor,
                    },
                  })}
                />
              </button>
            )}
          </div>
        )}
      </ImageUploading>
      {/* <p className={styles.takeLotImage}>{t('takeLotImage')}</p> */}
    </div>
  );
};
