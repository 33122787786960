import React , { useRef, useEffect, useState, useCallback } from 'react';
import xss from 'xss';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import styles from '../css/reclamation.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faComment, faClose} from '@fortawesome/free-solid-svg-icons';
import { companies } from './companies';


export const Comment = ({showModalComment, isButtonVisible, onScroll}) => {

  const { t, i18n } = useTranslation();
  const handleCommentBtnClick = () => {
      // Handle the click event
      onScroll();
      showModalComment();
  };

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {
      
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


    return(
        <div>
            <div
            className={classNames(styles.styledDivBtn, styles.commentButton)}
            id="show"
            onClick={handleCommentBtnClick}
            style={{ display: isButtonVisible ? 'flex' : 'none' }}
            {...(selectedCompany && {
            style: {
              background: selectedCompany.colors.formGradient,
            },
          })}
            >
            <span className={styles.textBtn} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} >
             <FontAwesomeIcon icon={faComment} style={{ marginRight: '5px' }} />
             {t('addComment')}
            </span>
            </div>
        </div>
        
        
    );
}

export const CommentTextArea = ({isTextareaVisible,comment, showModalComment}) =>{
  const { t, i18n } = useTranslation();
  // Split the comment into lines
  const lines = comment.split('\n');
  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {
      
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts

  // Truncate only the first line if it's longer than 20 characters
  // const truncatedComment = (lines[0].length > 20) || (lines.length>1) ? `${lines[0].substring(0, 20)}...Voir plus` : lines[0];
  const truncatedComment = (comment) => {
    const lines = comment.split('\n');
    const isRtl = i18n.language === 'ar';
    if (lines[0].length > 20 || lines.length > 1) {
      return (
        <div onClick={showModalComment} className={styles.commentTextArea} style={{ direction: isRtl ? 'rtl' : 'ltr' }}>
          {lines[0].substring(0, 20)}...<span className={styles.voirPlus}> {t('Voir plus')} </span>
        </div>
      );
    }
    return isTextareaVisible && <div onClick={showModalComment} className={styles.commentTextAreaLefted}
      {...(selectedCompany && isTextareaVisible && {
        style: {
          display: isTextareaVisible ? 'flex' : 'none',
          border: isTextareaVisible ? selectedCompany.colors.inputBorder : '',
        },
      })}>{comment}</div>;
  };
  // Join the truncated first line with the rest of the comment
  // const truncatedComment = [truncatedFirstLine, ...lines.slice(1)].join('\n');

  

  return (
      // <div id="form" className={styles.comment}>
      //     <textarea
      //         style={{ display: isTextareaVisible ? 'block' : 'none' }}
      //         id="textarea"
      //         rows="1"
      //         name="commentaire"
      //         readOnly
      //         value={truncatedComment(comment)}
      //         onClick={showModalComment}
      //         dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
      //     />
      // </div>
      <div className={styles.comment} 
      // style={{ display: isTextareaVisible ? 'flex' : 'none' }}
      >
        {truncatedComment(comment)}
      </div>
        
  );
}

export const CommentModal = ({ hideModalComment, isVisible, setTextareaVisible, setTextAreaText, setButtonVisibility, comment, setValue }) => {
  const modalContentRef = useRef(null);
  const textareaRef = useRef(null);
  const [localComment, setLocalComment] = useState(comment);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLocalComment(comment);
  }, [comment]);

  const submitComment = () => {
    const commentToSubmit = xss(localComment);
    setValue('comment', commentToSubmit);
    setTextAreaText(commentToSubmit);
    if (commentToSubmit !== '') {
      setTextareaVisible(true);
      setButtonVisibility(false);
    } else {
      setButtonVisibility(true);
      setTextareaVisible(false);
    }
    hideModalComment();
  };

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {
      
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts

  const handleCommentChange = (e) => {
    const value = e.target.value;

    // Updated regex to allow Arabic characters, Latin letters, numbers, spaces, ?, ,, and French accented characters
    const regex = /^[a-zA-Z0-9\s?,èàçéùäâêîôûëïöüÿÉÈÀÇÙ\u0600-\u06FF]{0,800}$/;

    // Check if the value matches the regex pattern
    if (regex.test(value)) {
      setLocalComment(value);
    }
  };

  return (
    <div>
      <div id="commentModal" className={classNames(styles.modalComment, { [styles.modalHidden]: !isVisible })}>
        <div ref={modalContentRef} className={styles.modalContentComment}>
          <span className={classNames(styles.closeComment, styles.pointedArea)} onClick={hideModalComment} style={{ color: 'red', opacity: 1 }}>
            <FontAwesomeIcon icon={faClose} />
          </span>
          <label> {t('writeComment')} </label>
          <textarea
            ref={textareaRef}
            className={styles.textareaPopup}
            id="textareaPopup"
            rows="8"
            cols="33"
            style={{ marginBottom: '10px' }}
            placeholder={t('writeComment')}
            maxLength="800"
            value={localComment}
            onChange={handleCommentChange}
            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            {...(selectedCompany && {
            style: {
              border: selectedCompany.colors.inputBorder,
            },
          })}
          ></textarea>
          <button type="button" className={styles.submitModalButton} onClick={submitComment}
          {...(selectedCompany && {
            style: {
              background: selectedCompany.colors.formGradient,
            },
          })}>
            {t('OK')}
          </button>
        </div>
      </div>
    </div>
  );
};
