import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { companies } from './companies';


/**
 * Importing CSS module to scope the styles locally to this component.
 * Using CSS modules ensures that the styles are applied only to the elements in this component,
 * avoiding any global scope pollution and preventing CSS conflicts.
 */
import styles from '../css/reclamation.module.scss';
import classNames from 'classnames';

export const CodeBarre = forwardRef(({
  setProduct,
  setProductVisibility,
  isVisible,
  setVisibility,
  setSelectedProducts,
  setProductListModalVisible,
  setNotFoundModalVisible,
  setUnknownProductModalVisible,
  setModalBarCodeVisible
}, ref) => {
  const [inputs, setInputs] = useState(['', '', '', '', '', '']);
  const [enabledInputs, setEnabledInputs] = useState([true, false, false, false, false, false]);
  const inputRefs = useRef([]);
  const [notFoundCount, setNotFoundCount] = useState(0);
  const [bdData, setBdData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [previousBarCode, setPreviousBarCode] = useState('');
  const [firstInputFocused, setFirstInputFocused] = useState(false); // New state variable
   // Reference to the input element

  // Expose focusFirstInput method to the parent component
  React.useImperativeHandle(ref, () => ({
    focusFirstInput: () => {
      console.log('focused');
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }
  }));
  
  const handleFirstInputFocus = () => {
    if (!firstInputFocused) {
      setFirstInputFocused(true); // Set to true to prevent future calls
      // Trigger blur immediately when focus is triggered
      if (inputRefs.current[0]) {
        console.log('blurred');
        inputRefs.current[0].blur(); // This will trigger the blur event immediately
      }
      const shown = localStorage.getItem('modalBarCodeShown'); 
      if(shown!=='true'){
        setModalBarCodeVisible(true);
      }
    }
  };


  // useEffect(() => {
  //   // Fetch the JSON data
  //   fetch(`https://liveio.site/jvc/BD_codebarre.json`)
  //     .then(response => response.json())
  //     .then(data => setBdData(data))
  //     .catch(error => console.error('Error fetching BD_codebarre.json:', error));
  // }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the JSON data from the server
        const response = await fetch(`${process.env.REACT_APP_DATA_URL}/BD_codebarre.json`);
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const data = await response.json();
        setBdData(data);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching BD_codebarre.json:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this useEffect runs once after the initial render

  useImperativeHandle(ref, () => ({
    focusFirstInput: () => {
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }
  }));

  const handleChange = (index, value) => {
    if (value.length > 1) return; // Prevent entering more than one digit

    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);

    if (value && index < 5) {
      inputRefs.current[index + 1].removeAttribute('disabled');
      inputRefs.current[index + 1].focus();
    }

    if (index === 5 && value) {
      searchCode(newInputs.join(''));
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      // If backspace is pressed and current input is empty, move to the previous input
      if (inputs[index] === '' && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        // If backspace is pressed and the current input has value, clear it
        const newInputs = [...inputs];
        newInputs[index] = '';
        setInputs(newInputs);
      }
    } else if (event.key === 'Delete') {
      // Delete the current input and move to the previous input
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else if(event.key === 'ArrowLeft'){
      if(index > 0){
        inputRefs.current[index - 1].focus();
      }
    } else if(event.key === 'ArrowRight'){
      if(index < 5){
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const searchCode = (barCode) => {
    const matches = bdData.filter(item => item.Code.slice(-6) === barCode);
    const marks = matches.map(item => item.Mark);
    setInputs(['', '', '', '', '', '']);
    setEnabledInputs([true, false, false, false, false, false]);

    if (marks.length === 1) {
      setProductVisibility(true);
      setProduct(marks);
      setVisibility(false);
    } else if (marks.length > 0) {
      setSelectedProducts(marks);
      setProductListModalVisible(true);
    } else {
      if (notFoundCount === 0) {
        console.log('here');
        setNotFoundModalVisible(true);
        setNotFoundCount(1);
      } else {
        if (previousBarCode === barCode) {
          setUnknownProductModalVisible(true);
          setNotFoundCount(0); // Reset the count after showing the second modal
          setPreviousBarCode(''); // Reset the previous barcode
        } else {
          // If a new barcode is scanned
          setPreviousBarCode(barCode);
          setNotFoundCount(1); // Reset count to 1 for the new barcode
          setNotFoundModalVisible(true);
        }
      }

      
    }
  };

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {
      
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


  return (
    <div className={styles.divCode} id="div-code">
      <div className={styles.inputField} style={{ display: isVisible ? 'flex' : 'none' }}>
        {inputs.map((input, index) => (
          <input
            key={index}
            type="number"
            pattern="\d"
            placeholder="-"
            value={input}
            ref={(el) => (inputRefs.current[index] = el)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onChange={(e) => handleChange(index, e.target.value)}
            onFocus={index === 0 ? handleFirstInputFocus : undefined} 
            disabled={!enabledInputs[index]}
            {...(selectedCompany && {
              style: {
                border: selectedCompany.colors.inputBorder,
              },
            })}
          />
        ))}
      </div>
    </div>
  );
});


export const ProductListModal = ({products, isVisible, setIsVisible, setProduct,
  setProductVisibility, setProductInputsVisibility}) =>{
  
    const modalContentRef = useRef(null);
    const { t, i18n } = useTranslation();
    const handleClickOutside = useCallback((event) => {
      if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
        console.log('hide');
        setIsVisible(false);
      }
    }, [setIsVisible]);
  
    useEffect(() => {
      if (isVisible) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isVisible, handleClickOutside]);
  
  const hidePopup = () => {
    setIsVisible(false);
  };

  const selectProduct = (product) =>{
    setProduct(product);
    setProductVisibility(true);
    setProductInputsVisibility(false);
    setIsVisible(false);
  }

  return(
    <div id="productPopup-mobile" style={{ display: isVisible ? 'flex' : 'none' }}
      className={classNames(styles.productPopupMobile,styles.modal)}>
      <div ref={modalContentRef} className={styles.productPopupContentMobile} >
        <button
          type="button"
          id="popupCloseButton-mobile"
          className={styles.popupCloseButtonMobile}
          onClick={hidePopup}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <p className="popup_text-mobile" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
          {t('selectProdListSentence')}
          
        </p>
        <div id="productChoices-mobile">
        {products !== null && products.map((product) => (
              <div key={product}>
                <input
                  type="radio"
                  id={`product-${product}`}
                  name="product"
                  value={product}
                />
                <label htmlFor={`product-${product}`} onClick={() => selectProduct(product)}>{product}</label>
                
              </div>
            ))}
        </div>
      </div>
    </div>
);
}