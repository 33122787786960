import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
/** Using Helmet to secure our page meta tags */
const MetaTags = () => (
  <HelmetProvider>
    <Helmet>
        <meta http-equiv="Cache-Control" content="no-store, no-cache, must-revalidate, max-age=0"/>
        <meta http-equiv="Pragma" content="no-cache"/>
        <meta http-equiv="Expires" content="0"/>

        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0 "/>
        <meta http-equiv="X-UA-Compatible" content="ie=edge"/>
        <title>Reclamation qualité</title>
        <link rel="icon" href="assets/images/app-icon.png"/>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css"
            integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"/>

        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"/>
        <link rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.19.0/font/bootstrap-icons.css"/>

        <link href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.3.0/css/datepicker.css" rel="stylesheet"
            type="text/css" />
        <link rel="stylesheet" href="css/style.min.css?v=13"/>
        <link rel="stylesheet" href="css/prix.css?v=10"/>
        <link rel="stylesheet" href="css/style_rec.min.css?v=22"/>
        <link rel="stylesheet" href="css/confetti.min.css"/>

        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet"/>
    </Helmet>
  </HelmetProvider>
);

export default MetaTags;