import React, {useRef, useEffect, useState} from 'react';
import styles from '../css/reclamation.module.scss'; 
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {backendLink} from '../config';
import { companies } from './companies';

export const ReclamationTypes = ({selectedTypes,setSelectedTypes, onScroll, typesInfoContainerRef, 
  containerRef, product, goBack}) => {
    const { t } = useTranslation();
    const scrolledItems = useRef(new Set());
    const [types, setTypes] = useState([]);
    // const typesInfoContainerRef = useRef(null);
    const selectType = (type, element) => {
      // onScroll();
      let updatedSelectedTypes;
      if (selectedTypes.includes(type.value)) {
          updatedSelectedTypes = selectedTypes.filter((t) => t !== type.value);
      } else {
          if (selectedTypes.length >= 2) {
              updatedSelectedTypes = [...selectedTypes.slice(1), type.value];
          } else {
              updatedSelectedTypes = [...selectedTypes, type.value];
          }
      }
      setSelectedTypes(updatedSelectedTypes);
      if (!scrolledItems.current.has(type.value)) {
        scrollToTypeInfoContainer(element);
        scrolledItems.current.add(type.value); // Mark the element as scrolled
      }
  };

  // const scrollToTypeInfoContainer = (element) => {
  //   if (element) {
  //       // Scroll the clicked element into view within its container
  //       element.scrollIntoView({
  //           behavior: 'smooth', // Smooth scroll animation
  //           block: 'center',
  //       });
  //   }
  // };

  const scrollToTypeInfoContainer = (element) => {
    if (element && containerRef.current) {
        const container = containerRef.current;

        // Get the current scroll position of the container
        const currentScrollTop = container.scrollTop;

        // Get the element's position relative to the container
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Calculate the element's position relative to the container's scroll position
        const elementPosition = elementRect.top - containerRect.top + currentScrollTop;

        // Get the height of the container's visible area
        const containerHeight = container.clientHeight;

        // Calculate the scroll position to center the element
        const scrollPosition = elementPosition - containerHeight / 2 + elementRect.height / 2;

        if(scrollPosition>currentScrollTop){
          // Scroll the container to center the element
            container.scrollTo({
              top: scrollPosition,
              behavior: 'smooth',  // Smooth scroll
          });
        }

        
    }
  };

  useEffect(()=>{
    setSelectedTypes([]);
    if(product !== '' && product !== null){
      let formData = new FormData();
      formData.append('product', product);
      fetch(`${backendLink}getTypes.php`,{
        method:'POST',
        body : formData,
        credentials: 'include'
      })
      .then(response => {
        if (response.status === 403) {
            // If status is 403, call goBack() and return to stop further execution
            goBack();
            return;  // Exit the promise chain
        }
        return response.json();  // Continue processing if status is not 403
      })
      .then(data => {
        data.push({ value: "Autre", "0": "Autre" });
        setTypes(data);
      })
      .catch(error => {
        console.error('Error fetching CSRF token:', error);
      });
    }else{
      setTypes([]);
      // setTypes([{value:"Autre"}]);
    }
    
  },[product, setSelectedTypes]);
  

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {
      
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


  return (
      <div className={styles.productGrid} ref={typesInfoContainerRef}>
        {types.map((type) => (
          <div
            className={classNames(styles.selectItem, {
              [styles.selectItemSelected]: selectedTypes.includes(type.value),
            })}
            key={type.value}
            onClick={(e) => selectType(type, e.currentTarget)}
            {...(selectedCompany && {
              style: {
                border: selectedCompany.colors.borderTypeColor,
                color: selectedCompany.colors.typeTextColor,
              },
            })}
          >
            {t(`types.${type.value}`)}
          </div>
        ))}
      </div>
    );
}
