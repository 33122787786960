import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n'; // Import the i18n 
import packageJson from '../package.json';

import { I18nextProvider } from 'react-i18next';
import CacheBuster from 'react-cache-buster';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
const isProduction = process.env.NODE_ENV === 'production';
const version = packageJson.version;
const buildTime = packageJson.buildTime || new Date().toISOString();

root.render(
  <React.StrictMode>
    <CacheBuster
      currentVersion={version}
      buildTime={buildTime}
      isProduction={isProduction}
      isEnabled={true} 
    >
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </CacheBuster>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     if (window.confirm("Une nouvelle version est disponible ! Voulez-vous mettre à jour?")) {
//       if (registration && registration.waiting) {
//         registration.waiting.postMessage({ type: 'SKIP_WAITING' });

//         registration.waiting.addEventListener('statechange', (event) => {
//           if (event.target.state === 'activated') {
//             window.location.reload();
//           }
//         });
//       }
//     }
//   },
// });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

