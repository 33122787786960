import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import styles from '../css/reclamation.module.scss';
import { companies } from './companies';
import { useTranslation } from 'react-i18next';


export const ProductInput = ({isVisible, setIsVisible, product, setProductInputsVisibility, setProduct}) => {
  
  const hideProductInput = (event) => {
    event.stopPropagation();
    setIsVisible(false);
    setProductInputsVisibility(true);
    setProduct('');
  };

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {
      
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


  return (
    <div className={styles.productInputContainer} id="productinputContainer" 
    style={{ position: 'relative', display: isVisible ? 'block' : 'none', marginBottom:"5px", marginTop:"20px" }}>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <input
          id="productName"
          name="nom_produit"
          placeholder="Nom du produit"
          style={{ width: '100%' }}
          readOnly
          value={product}
          onClick={(event) => event.stopPropagation()}
          {...(selectedCompany && {
            style: {
              border: selectedCompany.colors.inputBorder,
            },
          })}
        />
        <button
        className={styles.hideProductInputButton}
          type="button"
          id="hideProductInputButton"
          onClick={hideProductInput}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      </div>
    </div>
  );
};

export const ProductInputCommercant = ({ index, isVisible, setIsVisible, product, setProductInputsVisibility, removeProduct }) => {
  
  const { t, i18n } = useTranslation();
  const hideProductInput = (event) => {
    event.stopPropagation();
    setIsVisible(false);
    setProductInputsVisibility(true);
    removeProduct(index);  // Call the remove function with the index
  };

  return (
    <div className={styles.productInputContainer} id="productinputContainer" style={{ position: 'relative', display: isVisible ? 'block' : 'none' }}>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <input
          id="productName"
          name="nom_produit"
          placeholder="Nom du produit"
          style={{ width: '100%', marginTop:'0' }}
          readOnly
          value={product}
          onClick={(event) => event.stopPropagation()}
          dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
        />
        <button
          className={styles.hideProductInputButton}
          type="button"
          id="hideProductInputButton"
          onClick={hideProductInput}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      </div>
    </div>
  );
};

