import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const MetaTags = () => (
  <HelmetProvider>
    <Helmet>
      <meta http-equiv="Cache-Control" content="no-store, no-cache, must-revalidate, max-age=0" />
      <meta http-equiv="Pragma" content="no-cache" />
      <meta http-equiv="Expires" content="0" />
      <meta charset="UTF-8" />
      <script src="https://cdn.jsdelivr.net/npm/sweetalert2@10"></script>
      <meta name="viewport" content="width=device-width, initial-scale=1.0 " />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="apple-mobile-web-app-title" content="Jawda" />
      <link rel="apple-touch-icon" href="assets/images/app-icon.png" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />
      <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet" />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/quagga/0.12.1/quagga.min.js"></script>
      <link rel="stylesheet" href="css/firstPage.css?v=7" />
      <link rel="stylesheet" href="css/firstPage2.css" />
      <link rel="manifest" href="manifest.json" />
      <title>Jawda plus</title>
      <link rel="icon" href="assets/images/app-icon.png" />
      <script src="https://unpkg.com/sweetalert/dist/sweetalert.min.js"></script>
    </Helmet>
  </HelmetProvider>
);

export default MetaTags;
