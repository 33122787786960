// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const supportedLanguages = ['fr', 'ar']; // List of supported languages

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr', // Set fallback language to French
    debug: true,
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
    backend: {
      loadPath: '/user/locales/{{lng}}/translation.json', // Path to your translation files
    },
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
    },
    load: 'currentOnly', // Only load the current language
    supportedLngs: supportedLanguages, // List of supported languages

    // This function detects the initial language if none is set
    initImmediate: false,
    lng: 'ar', // Default to Arabic ('ar')
  });

export default i18n;
