import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from '../css/landing.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';




const TermsOfUse = ({ isVisible, onRefuse, onAccept }) => {
  const { t, i18n } = useTranslation();
  
   
  return (
    <div className={`${styles.modal} ${!isVisible ? styles.modalHidden : ''}`}>
      <div className={styles.modalContent}>
      <div className={styles.modalHeader}>
        <span onClick={onRefuse}><FontAwesomeIcon icon={faTimes} /></span>
      </div>
        <h3 className={classNames(styles.modalTitle, { [styles.arText]: i18n.language === 'ar' })}> {t('TermsOfUse')} </h3>
        <p className={classNames( i18n.language === 'fr' ? styles.termsParagraph : styles.termsParagraphAr )}>
          {t('TermsExplanation')}
          
        </p>
        <a
            href={`${process.env.PUBLIC_URL}/conditions_d_utilisation.html`}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames( i18n.language === 'ar' ? [styles.arText]  : styles.link )}
          >
          {t('ReadConditions')}
          </a>
        {/* <a
            href={`${process.env.PUBLIC_URL}/terms_of_use.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(styles.link, { [styles.arText]: i18n.language === 'ar' })}
          >
          {t('ReadConditions')}
          </a> */}
        
        <div className={classNames(styles.modalFooter)}>
            <button className={classNames(styles.refuseCond, styles.condButton)} onClick={onRefuse}>{t('refuseCond')}</button>
            <button className={classNames(styles.acceptCond, styles.condButton)} onClick={onAccept}> {t('acceptCond')} </button>
        </div>
       
      </div>
    </div>
  );
};

export default TermsOfUse;
