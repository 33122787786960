import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, IconButton, Backdrop, Fade } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import {backendLink, uploadsLink} from '../config';
import styles from '../css/landing.module.scss';
import classNames from 'classnames';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';

const AlertsModal = ({open, setOpen }) => {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust 768px for mobile breakpoint
    };
    
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const hasShownModal = sessionStorage.getItem('hasShownModal');

  useEffect(() => {
    fetch(`${backendLink}/getAlerts.php`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        setAlerts(data);
        if (data.length > 0 && !hasShownModal ) {
          setTimeout(() => {
            setOpen(true);  // Set open to true after a 3-second delay
          }, 2000);  // 3000 milliseconds = 3 seconds
          sessionStorage.setItem('hasShownModal', 'true');
        }
        // else if (hasShownModal && data.length > 0 ){
        //   let newAlerts = data.filter(alert => 
        //     !alerts.some(existingAlert => existingAlert.id === alert.id)
        //   );
        //   if(newAlerts.length > 0){
        //     console.log(newAlerts);
        //     setAlerts(data);
        //     setOpen(true);
        //   }
        // }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching alerts:', error);
        setLoading(false);
      });
  }, [backendLink]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % alerts.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + alerts.length) % alerts.length);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious,
  });

  if (loading) {
    return <p></p>;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: { 
            backgroundColor: 'rgba(0, 0, 0, 0.5)' 
          }
        },
      }}
    >
    <Fade in={open}>
        <Box
        className={styles.alertModalBox}
        onClick={onClose}
    >
      <Box
        className={styles.innerAlertModalBox}
        onClick={(e) => e.stopPropagation()}
        
        {...handlers} // Apply swipe handlers
      >
      {isMobile && 
          <IconButton className={styles.closeAlertIconButton} onClick={onClose}>
              <CloseIcon className={styles.closeAlertIcon}/>
            </IconButton>
      }
            
        {alerts.length > 0 && (
            <div className={styles.alertsContainer}>
            {alerts.length > 1 && !isMobile && (
              <Button onClick={handlePrevious} variant="contained" className={classNames(styles.alertSwiper, styles.previousSwiper)}>
                <ArrowBackIosIcon />
              </Button>
            )}
                
            <div className={styles.alertContainer} >
                <Box
                sx={{
                    // width: '100%',
                    height: '50%',
                    mb: 2,
                    overflow: 'hidden',
                    borderRadius: 1,
                }}
                >
                <img
                    src={`${uploadsLink}/${alerts[currentIndex].image}`} // Use currentIndex
                    alt="Alert"
                    style={{ height: '100%', objectFit: 'cover' }}
                />
                </Box>
                <Typography variant="h6" component="h2" gutterBottom className={styles.alertTitle} >
                {alerts[currentIndex].title} 
                </Typography>
                <Typography sx={{ mb: 2 }} className={styles.alertDescription} >
                {alerts[currentIndex].text} 
                </Typography>
                {isMobile ? (
                  // Show points for mobile view
                  <div className={styles.pointsContainer}>
                    {alerts.map((_, index) => (
                      <span
                        key={index}
                        className={classNames(styles.alertPoint, index === currentIndex ? styles.activePoint : '')}
                      >
                        ●
                      </span>
                    ))}
                  </div>
                ) : (
                  // Show empty div as per desktop view structure
                  <div></div>
                )}
                <Button onClick={onClose} variant="contained" sx={{ mt: 2 }} className={styles.alertButton} >
                    Fermer
                </Button>

                
            </div>

            {alerts.length > 1 && !isMobile && (
              <Button onClick={handleNext} variant="contained" className={classNames(styles.alertSwiper, styles.nextSwiper)}>
                <ArrowForwardIosIcon />
              </Button>
            )}
            </div>
        )}
      </Box>
        </Box>
      </Fade>
    
    </Modal>
  );
};

export default AlertsModal;
