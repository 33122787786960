import { companies } from '../components/companies';
import { backendLink } from '../config.js';
import React, { useState, useEffect, useRef, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import xss from 'xss';

import '../css/datePicker.scss';
import styles from '../css/reclamation.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowPointer, faArrowRight, faCircleXmark, faArrowLeft, faListAlt, faClipboardList } from '@fortawesome/free-solid-svg-icons';

import { useForm, Controller, FormProvider, } from 'react-hook-form';
import classNames from 'classnames';
import MetaTags from '../components/MetaTagsReclamation';
import Header from '../components/ReclamationHeader';
import { CodeBarre } from '../components/CodeBarre';

import { DropDown, DropDownLocation } from '../components/DropDown';
import { DatePickerComponent } from '../components/DatePicker';
import { ReclamationTypes } from '../components/ReclamationTypes';
import { ImageUploader } from '../components/ImageUploader.js';
import { LotImageUploader } from '../components/LotImageUploader.js';
import { Comment, CommentTextArea } from '../components/Comment';
import { ProductInput } from '../components/ProductInput';
import ThanksPopup from '../components/ThanksPopUp.js';
import LanguageSwitcher from '../components/LanguageSwitcher.js';
import InstallPopup from '../components/InstalPopup.js';
import InstallPrompt from '../components/InstallPrompt';

const ProductListModal = React.lazy(() => import('../components/CodeBarre').then(module => ({ default: module.ProductListModal })));
const ModalImage = React.lazy(() => import('../components/Modal').then(module => ({ default: module.ModalImage })));
const CommentModal = React.lazy(() => import('../components/Comment').then(module => ({ default: module.CommentModal })));
const PhoneModal = React.lazy(() => import('../components/Phone').then(module => ({ default: module.PhoneModal })));
const NotFoundModal = React.lazy(() => import('../components/NotFoundModal').then(module => ({ default: module.NotFoundModal })));
const UnknownProductModal = React.lazy(() => import('../components/UnknownProductModal').then(module => ({ default: module.UnknownProductModal })));
const LotModal = React.lazy(() => import('../components/LotModal').then(module => ({ default: module.LotModal })));
const ProdListPopup = React.lazy(() => import('../components/prodListPopup').then(module => ({ default: module.ProdListPopup })));
const FailureToast = React.lazy(() => import('../components/SuccessToast').then(module => ({ default: module.FailureToast })));

const Reclamation = ({ currentLang, setCurrentLang, isPWAInstallable, onInstallClick }) => {
  const [key, setKey] = useState(0);
  const [csrfToken, setCsrfToken] = useState('');
  const [isThanksPopUpVisible, setThanksPopUpVisible] = useState(false);
  const [isModalBarCodeVisible, setModalBarCodeVisible] = useState(false);
  const [isModalCommentVisible, setModalCommentVisible] = useState(false);
  const [isTextareaVisible, setTextareaVisible] = useState(false);
  const [isCommentButtonVisible, setCommentButtonVisible] = useState(true);
  const [isModalPhoneVisible, setModalPhoneVisible] = useState(false);
  const [isInputPhoneVisible, setInputPhoneVisible] = useState(false);
  const [isModalLotVisible, setModalLotVisible] = useState(false);
  const [comment, setComment] = useState('');
  const [phone, setPhone] = useState('');
  const [isSticky, setIsSticky] = useState(false);
  const [isProductInputVisible, setProductInputVisible] = useState(false);
  const [isBarCodeVisible, setBarCodeVisible] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [isProductListModalVisible, setProductListModalVisible] = useState(false);
  const [NotFoundModalVisible, setNotFoundModalVisible] = useState(false);
  const [UnknownProductModalVisible, setUnknownProductModalVisible] = useState(false);
  const codeBarreRef = useRef(null);
  const [isFirstFocus, setIsFirstFocus] = useState(true);
  const inputRef = useRef(null);
  const [resetRadioButton, setResetRadioButton] = useState(false);
  const prodInfoContainerRef = useRef(null);
  const typesInfoContainerRef = useRef(null);
  const imgCmntContainerRef = useRef(null);
  const locationContainerRef = useRef(null);
  const containerRef = useRef(null);
  const [emailError, setEmailError] = useState(false);
  const [isBarCodeInputVisible, setIsBarCodeInputVisible] = useState(false);
  const [isDropDownInputVisible, setIsDropDownInputVisible] = useState(false);
  const [otherProdInfo, setOtherProdInfo] = useState(false);
  const [popUpProdListVisibility, setPopUpProdListVisibility] = useState(false);
  const [picChoice, setPicChoice] = useState(true);
  const [showToastFields, setShowToastFields] = useState(false);

  const showProdListPopup = () => {
    setPopUpProdListVisibility(true);
  }

  // Use useEffect to watch for changes in isBarCodeInputVisible or isDropDownInputVisible
  useEffect(() => {
    if ((isProductInputVisible) && !otherProdInfo) {
      setOtherProdInfo(true);
    }
  }, [isProductInputVisible, otherProdInfo]);

  useEffect(() => {
    if (isModalBarCodeVisible) {
      localStorage.setItem('modalBarCodeShown', true);
    }
  }, [isModalBarCodeVisible])

  const scrollToProdInfoContainer = () => {
    if (prodInfoContainerRef.current) {
      console.log('called');
      
      // Use scrollIntoView to automatically scroll to the element
      prodInfoContainerRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scroll animation
        block: 'end',
      });
  
      // Apply shake effect when scrolling to the container
      prodInfoContainerRef.current.classList.add('shake');
  
      // After the shake animation ends, remove the shake class
      setTimeout(() => {
        prodInfoContainerRef.current.classList.remove('shake');
      }, 1000);  // Adjust this time to match the total duration of the animation (500ms)
    }
  };

  const scrollToDateLevel = () =>{
    if (typesInfoContainerRef.current) {
      console.log('called');
      // Use scrollIntoView to automatically scroll to the element
      typesInfoContainerRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scroll animation
        block: 'end',
      });
    }
  }
  

  const scrollToTypeInfoContainer = () => {
    if (typesInfoContainerRef.current) {
      console.log('called');
      // Use scrollIntoView to automatically scroll to the element
      typesInfoContainerRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scroll animation
        block: 'end',
      });
  
      // Apply zoom-out effect first when scrolling to the container
      // typesInfoContainerRef.current.classList.add('zoom-out');
  
      // // After a delay (for zoom-out animation), apply zoom-in effect
      // setTimeout(() => {
      //   typesInfoContainerRef.current.classList.remove('zoom-out');
      //   typesInfoContainerRef.current.classList.add('zoom-in');
      // }, 500);  // Delay to allow zoom-out animation to happen (adjust as needed)
  
      // // After the zoom-in animation ends, remove both zoom-in and zoom-out classes
      // setTimeout(() => {
      //   typesInfoContainerRef.current.classList.remove('zoom-in');
      // }, 2000);  // Adjust this time to match the total duration of the animation

      // Apply shake effect when scrolling to the container
      typesInfoContainerRef.current.classList.add('shake');
  
      // After the shake animation ends, remove the shake class
      setTimeout(() => {
        typesInfoContainerRef.current.classList.remove('shake');
      }, 1000);  // Adjust this time to match the total duration of the animation (500ms)
    }
  };
  
  


  // const scrollToImgCmntContainer = () => {
  //   // if (imgCmntContainerRef.current) {
  //   //   // First, scroll it into view
  //   //   imgCmntContainerRef.current.scrollIntoView({
  //   //     behavior: 'smooth',
  //   //     block: 'start',
  //   //   });
  //   // }

  //   if (imgCmntContainerRef.current) {
  //     const element = imgCmntContainerRef.current;
  //     const elementRect = element.getBoundingClientRect();
  //     const absoluteElementTop = elementRect.top + window.pageYOffset;
  //     const middle = absoluteElementTop - (window.innerHeight / 2);
  //     window.scrollTo(0, middle);
  //   }
  // }

  const scrollToImgCmntContainer = () => {
    if (imgCmntContainerRef.current) {
      // Use scrollIntoView to automatically scroll to the element
      imgCmntContainerRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scroll animation
        block: 'center',    // Center the element in the view
      });
    }
  };

  const scrollToLocationContainer = () => {
    if (locationContainerRef.current) {
      // First, scroll it into view
      locationContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    const digits = value.replace(/\D/g, '');

    // Format the number with spaces after every two digits
    const formatted = digits.match(/.{1,2}/g)?.join(' ') || '';

    return formatted.trim();
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${backendLink}get_csrf_token.php`, {
      method: 'GET',
      credentials: 'include'
    })
      // .then(response => {
      //   if (!response.ok) {
      //     throw new Error('Network response was not ok');
      //   }
      //   return response.json();
      // })
      .then(response => {
        if (response.status === 403) {
            // If status is 403, call goBack() and return to stop further execution
            goBack();
            return;  // Exit the promise chain
        }
        return response.json();  // Continue processing if status is not 403
      })
      .then(data => {
        // setCsrfToken(data.csrf_token);
      })
      .catch(error => {
        console.error('Error fetching CSRF token:', error);
      });
  }, []);

  const resetFormAndState = () => {
    // window.location.reload();
    navigate(0);
  };

  const goBack = () => {
    navigate('/');
  }

  /** handleFocus is defined to handle first time focus on n_lot input to show the user
   * an image (in lotModal) to know where he can find batch number in product packaging
  */
  const handleFocus = (e) => {
    const shown = localStorage.getItem('modalLotVisible');
    if (isFirstFocus && shown !== 'true') {
      setIsFirstFocus(false);
      setModalLotVisible(true);
      localStorage.setItem('modalLotVisible', true);
      e.target.blur();
    }
  };

  /** When LotModal is hidden cursor must be focused on Lot input */
  const focusLotInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  /** When the user enters the last 6 numbers and they are not found the popup indicating it 
   * is displayed and when it's closed cursor must be focused again in codebarre input
   */
  const handleModalNotFoundClose = () => {
    console.log('focused');
    setNotFoundModalVisible(false);
    if (codeBarreRef.current) {
      console.log('focused 2');
      codeBarreRef.current.focusFirstInput();
    }
  };

  /** Define default inputs' values of form */
  const methods = useForm({
    defaultValues: {
      selectedTypes: [],
      location: { wilaya: '', commune: '' },
      product: '',
      n_lot: '',
      selectedDate: null,
      comment: '',
      phone: '',
      compressedFiles: [],
      compressedLot: [],
      mail: ''
    },
  });

  /**
   * Destructuring the methods object provided by react-hook-form.
   * - handleSubmit: Function to handle form submission.
   * - control: Object to control form elements and integrate with React Hook Form.
   * - setValue: Function to set the value of a specific form field.
   * - getValues: Function to get the current values of all form fields.
   *
   * These functions and objects are used to manage the form's state, validation, and submission in a declarative manner.
  */
  const { handleSubmit, control, setValue, getValues } = methods;

  // Load data from localStorage on component mount
  // useEffect(() => {
  //   const savedData = JSON.parse(localStorage.getItem("formData"));
  //   if (savedData) {
  //     let types;
  //     if ("selectedTypes" in savedData && savedData.selectedTypes) {
  //       types = savedData.selectedTypes
  //     }

  //     Object.keys(savedData).forEach((key) => {
  //       if (key === "selectedDate" && savedData[key]) {
  //         setValue(key, new Date(savedData[key])); // Convert to Date object
  //       } else {
  //         setValue(key, savedData[key]);
  //       }
  //     });

  //     if ("product" in savedData && savedData.product) {
  //       setProductInputVisible(true);
  //       setBarCodeVisible(false);
  //     }

  //     // Delay the execution of the "types" block
  //     if (types) {
  //       setTimeout(() => {
  //         console.log(types);
  //         setValue("selectedTypes", types);
  //       }, 3000); // Delay of 3000 milliseconds (3 seconds)
  //     }

  //     // if ("product" in savedData && savedData.product) {
  //     //   console.log(savedData.product);
  //     //   setValue("product", savedData.product);
  //     //   setProductInputVisible(true);
  //     //   if(types){
  //     //     console.log(types);
  //     //     setValue("selectedTypes", types);
  //     //   }
  //     // }

  //     if ("phone" in savedData && savedData.phone) {
  //       setValue("phone", savedData.phone);
  //     }
  //     if ("location" in savedData && savedData.location) {
  //       setValue("location", savedData.location);
  //     }
  //   }
  // }, [setValue]);

  // Save form data to localStorage on change
  useEffect(() => {
    const subscription = methods.watch((value) => {
      localStorage.setItem("formData", JSON.stringify(value));
    });
    return () => subscription.unsubscribe();
  }, [methods]);

  const verifyInputs = (data) => {
    // Check if product is not empty
    if (!data.product) {
      return false;
    }

    // Check if selectedTypes is not empty
    if (!data.selectedTypes || data.selectedTypes.length === 0) {
      return false;
    }

    // Check if (n_lot is not empty and selectedDate is not null) OR compressedLot.length > 0
    // if (!(data.n_lot && data.selectedDate) && (data.compressedLot.length === 0)) {
    //   return false;
    // }

    // Check if wilaya is not empty
    // if (!data.location || !data.location.wilaya) {
    //   return false;
    // }

    // Check if commune is not empty
    // if (!data.location.commune) {
    //   return false;
    // }

    // All conditions passed, return true
    return true;
  }

  const markMissingFields = (data) => {
    // Check if product is not empty
    if (!data.product) {
      // return false;
      scrollToProdInfoContainer();
    }

    // Check if selectedTypes is not empty
    if (!data.selectedTypes || data.selectedTypes.length === 0) {
      // return false;
      scrollToTypeInfoContainer();
    }
  }

  /** called on submitting form handleSubmit(onSubmit) */
  const onSubmit = async (data, event) => {
    if (!verifyInputs(data)) {
      console.log('error');
      setShowToastFields(true);
      markMissingFields(data);
      return;
    }

    if (data.mail !== '' && !isValidEmail(data.mail)) {
      setEmailError(true);  // Show the error message
      return;
    } else {
      setEmailError(false); // Reset the error message if the email is valid
    }
    event.preventDefault();
    setThanksPopUpVisible(true);
    try {
      const formData = new FormData();
      formData.append('name', data.nom ?? '');
      formData.append('nom_produit', data.product);
      formData.append('n_lot', data.n_lot);
      if (data.selectedDate !== null) {
        formData.append('date_fabrication', data.selectedDate.toISOString());
      } else {
        formData.append('date_fabrication', '0000-00-00');
      }
      formData.append('type_reclamation', data.selectedTypes);
      formData.append('description', data.comment);
      formData.append('region', data.location.wilaya);
      formData.append('commune', data.location.commune);
      formData.append('phone_num', data.phone);
      formData.append('envoyer', true);
      formData.append('user', 0);
      formData.append('mail', data.mail)
      // formData.append('_csrf', csrfToken);

      // Helper function to convert base64 to Blob
      const base64ToBlob = (base64, mimeType) => {
        try {
          // Ensure the base64 string is properly formatted
          const base64Data = base64.includes(',') ? base64.split(',')[1] : base64;

          // Decode Base64
          const byteCharacters = atob(base64Data);

          // Create byte numbers array
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          // Convert byte numbers to a byte array and create a Blob
          const byteArray = new Uint8Array(byteNumbers);
          return new Blob([byteArray], { type: mimeType });
        } catch (error) {
          console.error("Failed to convert base64 to Blob:", error);
          // throw error; // Re-throw to handle it elsewhere if needed
        }
      };


      /** Retrieve compressed images and store them in formData */
      if (data.compressedFiles.length > 0) {
        data.compressedFiles.forEach((fileData) => {

          const blob = base64ToBlob(fileData.base64, fileData.type);
          const file = new File([blob], fileData.name, {
            type: fileData.type,
            lastModified: fileData.lastModified,
          })
          formData.append('imagesAll[]', file);
        });
      }

      if (data.compressedLot.length > 0) {
        let fileData = data.compressedLot[0];
        const blob = base64ToBlob(fileData.base64, fileData.type);
        const file = new File([blob], fileData.name, {
          type: fileData.type,
          lastModified: fileData.lastModified,
        })
        formData.append('imageLot', file);
      }


      // Log the form data
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      localStorage.setItem('formData', null);

      const response = await fetch(`${backendLink}back.php`, {
        method: 'POST',
        body: formData,
        credentials: 'include' // Include cookies in the request for CSRF protection
      });

      if (response.status === 403) {
        // Handle the 403 Forbidden response
        alert("Un échec est survenu ! Veuillez réessayer d'envoyer votre réclamation. Merci !");
        goBack();
        return;
        // You can also redirect the user or take any other action
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  /** When window/form is scrolled the navbar containing logo 
   * => we need to add class sticky (for white background) otherwise navbar should not have the class sticky 
   * scroll is detected with useEffect react method
  */
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const SCROLL_THRESHOLD = 5;

  // const handleScroll = (e) => {
  //   if (e.target.scrollTop > SCROLL_THRESHOLD) {
  //     setIsSticky(true);
  //   } else {
  //     setIsSticky(false);
  //   }
  // };

  const handleScroll = (e) => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > SCROLL_THRESHOLD || e.target.scrollTop > SCROLL_THRESHOLD) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const hideModalPhone = () => {
    setModalPhoneVisible(false);
    setResetRadioButton(true);
  };

  const showModalComment = () => {
    setModalCommentVisible(true);
  };

  const hideModalComment = () => {
    setModalCommentVisible(false);
  };

  const showModalImage = () => {
    setModalBarCodeVisible(true);
  };

  const hideModalImage = () => {
    setModalBarCodeVisible(false);
    if (codeBarreRef.current) {
      console.log('focused 2');
      codeBarreRef.current.focusFirstInput();
    }
  };

  const handleCancel = () => {
    window.location.href = 'index.php';
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(currentLang);
  }, [currentLang, i18n]);

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {

      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts

  const formatMail = (value) => {
    return value;
    // Trim leading/trailing whitespace and convert to lowercase
    // const formattedValue = value.trim().toLowerCase();

    // // Basic email pattern to check if the email is valid
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // // If the email matches the pattern, return the formatted email, otherwise return an empty string
    // if (emailPattern.test(formattedValue)) {
    //   return formattedValue;
    // } else {
    //   return ''; // Or return an error message if you'd like
    // }
  };

  const isValidEmail = (value) => {
    // Basic email pattern to check if the email is valid
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the value matches the email pattern
    return emailPattern.test(value.trim().toLowerCase());
  };

  const barCodeChoice = (e) => {
    e.preventDefault();
    const shown = localStorage.getItem('modalBarCodeShown');
    if (shown !== 'true') {
      setModalBarCodeVisible(true);
    }
    setIsBarCodeInputVisible(true);
    setIsDropDownInputVisible(false);
  }

  const dropDownChoice = (e) => {
    e.preventDefault()
    setIsBarCodeInputVisible(false);
    setIsDropDownInputVisible(true);
  }



  return (
    <div className={classNames(styles.mainContainer, { [styles.notScrollable]: isModalCommentVisible })} onScroll={handleScroll} ref={containerRef}>
      <MetaTags />
      <Header isSticky={isSticky || isModalCommentVisible} currentLang={currentLang} setCurrentLang={setCurrentLang} />
      {/* <LanguageSwitcher currentLang={currentLang} setCurrentLang={setCurrentLang} /> */}
      {/** FormProvider is used to share form state with all nested components */}
      <FormProvider {...methods}>
        <div className={classNames(styles.container, styles.notScrollable)}>
          <h1 className={styles.titleForm}
            {...(selectedCompany && {
              style: {
                color: selectedCompany.colors.textColor,
              },
            })}
          > {t('titleForm')} </h1>
          <form className={styles.form} id="myform" onSubmit={handleSubmit(onSubmit)}>
            <div className={classNames(styles.infoContainer)} ref={prodInfoContainerRef}
              {...(selectedCompany && {
                style: {
                  boxShadow: selectedCompany.colors.containerShadow,
                },
              })}>
              <h4
                {...(selectedCompany && {
                  style: {
                    color: selectedCompany.colors.containerTitle,
                  },
                })}> {t('productQuestion')} </h4>
              {/** passing getValues function as prop to share form value of product */}
              {/* {!isProductInputVisible &&
                <p className={styles.provideLotDate} > {t('instructionProduit')} </p>
              } */}
              <ProductInput
                isVisible={isProductInputVisible}
                setIsVisible={setProductInputVisible}
                product={getValues('product')}
                setProductInputsVisibility={setBarCodeVisible}
                setProduct={(product) => setValue('product', product)}
              />
              <div className={styles.codeBarContainer} style={{ display: isBarCodeVisible ? 'flex' : 'none' }}>
                <p className={classNames(styles.brCodePar, i18n.language === 'ar' ? styles.brCodeParAr : null)}
                  dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} // Set direction based on current language
                  {...(selectedCompany && {
                    style: {
                      color: selectedCompany.colors.textColor,
                    },
                  })}
                >
                  {t('barCodeInstruction')}
                  {/* <span className={styles.inEg} onClick={(e) => {
                      e.preventDefault();
                      showModalImage();
                    }} > {t('inEg')} </span> */}
                  {/* <FontAwesomeIcon
                    icon={i18n.language === 'fr' ? faArrowRight : faArrowLeft}
                    className={styles.arrowEg}
                    style={{ marginLeft: i18n.language === 'fr' ? '8px' : '0', marginRight: i18n.language === 'ar' ? '8px' : '0', verticalAlign: 'middle' }} // Ensure vertical alignment
                    {...(selectedCompany && {
                      style: {
                        color: selectedCompany.colors.textColor,
                      },
                    })}
                  /> */}
                  {/* <button type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      showModalImage();
                    }}
                    className={styles.codeBarresButton}
                    id="codeBarresButton"
                    {...(selectedCompany && {
                      style: {
                        background: selectedCompany.colors.formGradient,
                      },
                    })}
                  >
                    {t('seeEg')} <FontAwesomeIcon icon={faArrowPointer} />
                  </button> */}
                  <button type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      showModalImage();
                    }}
                    className={styles.codeBarresButton}
                    id="codeBarresButton"
                    {...(selectedCompany && {
                      style: {
                        background: selectedCompany.colors.formGradient,
                      },
                    })}
                  >
                    {t('inEg')}
                  </button>
                </p>
              </div>
              {/* <div className={styles.divider} style={{ display: isBarCodeVisible ? 'flex' : 'none' }}>
                {t('Option1')}
              </div> */}
              {/** Passing setValue function as a prop to set the FORM value of product
              and ref prop to be able to control the focus on first input of CodeBarre */}
              {/* {!isProductInputVisible &&
                <div className={styles.buttonsChoiceContainer} >
                  <button
                    className={isBarCodeInputVisible ? styles.buttonChoiceActive : styles.buttonChoice}
                    onClick={(event) => barCodeChoice(event)}
                  >
                    {t('enterBarCode')}
                  </button>
                  <button
                    className={isDropDownInputVisible ? styles.buttonChoiceActive : styles.buttonChoice}
                    onClick={(event) => dropDownChoice(event)}> {t('chooseProductFromList')} </button>
                </div>
              } */}

              {/* {isBarCodeInputVisible && <CodeBarre
                setSelectedProducts={setSelectedProducts}
                setProductListModalVisible={setProductListModalVisible}
                setProduct={(product) => setValue('product', product)}
                setProductVisibility={setProductInputVisible}
                isVisible={isBarCodeVisible}
                setVisibility={setBarCodeVisible}
                setNotFoundModalVisible={setNotFoundModalVisible}
                setUnknownProductModalVisible={setUnknownProductModalVisible}
                ref={codeBarreRef}
                setModalBarCodeVisible={setModalBarCodeVisible}
              />} */}

              {/* {isDropDownInputVisible && <DropDown
                placeholder={t('chooseProduct')}
                setProductVisibility={setProductInputVisible}
                setProduct={(product) => setValue('product', product)}
                isVisible={isBarCodeVisible}
                setProductInputsVisibility={setBarCodeVisible}
              />} */}

              {isBarCodeVisible &&
                <div className={styles.codeListContainer} >
                  <CodeBarre
                    setSelectedProducts={setSelectedProducts}
                    setProductListModalVisible={setProductListModalVisible}
                    setProduct={(product) => setValue('product', product)}
                    setProductVisibility={setProductInputVisible}
                    isVisible={isBarCodeVisible}
                    setVisibility={setBarCodeVisible}
                    setNotFoundModalVisible={setNotFoundModalVisible}
                    setUnknownProductModalVisible={setUnknownProductModalVisible}
                    ref={codeBarreRef}
                    setModalBarCodeVisible={setModalBarCodeVisible}
                  />

                  {isBarCodeVisible &&
                    <button type='button' className={styles.prodListButton} onClick={showProdListPopup} >
                      <FontAwesomeIcon icon={faListAlt} className={styles.listIcon} />
                    </button>
                  }
                </div>}

              <div id="productInfos" className={styles.productInfos}>
                <div id="productinputContainer" className={styles.productInputContainer}>
                  <div>
                    <input
                      id="productName"
                      name="nom_produit"
                      placeholder="Nom du produit"
                      style={{ width: '100%' }}
                      readOnly
                      onClick={(event) => event.stopPropagation()}
                      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    />
                    <button
                      type="button"
                      id="hideProductInputButton"
                      onClick={(event) => {
                        event.stopPropagation();
                        hideProductInput(event);
                      }}
                    >
                      <FontAwesomeIcon icon={faCircleXmark} />
                    </button>
                  </div>
                </div>
                <label id="labelBarCode" style={{ display: 'none', margin: 'auto' }}>
                  {t('barCode')}
                </label>
                <input type="hidden" id="productLot" name="codebarre" style={{ width: '100%' }} readOnly />
                <input id="codeInputMobile" type="hidden" />
                <input id="nameInputMobile" type="hidden" />
              </div>
              {/* <div className={styles.divider} style={{ display: isBarCodeVisible ? 'flex' : 'none' }}>
                {t('Option2')}
              </div> */}



              {/* <Controller
                    name="compressedLot"
                    control={control}
                    render={({ field }) => (
                      <LotImageUploader setValue={setValue}
                        getValues={getValues}
                      // onScroll={scrollToImgCmntContainer}
                      />
                    )}
                  /> */}


              {otherProdInfo &&
                <>
                  <p className={styles.provideLotDate} > {t('InstructionLotDate')} </p>
                  <div className={styles.swipeBar}>
                    <button
                      type="button"
                      className={`${styles.swipeChoice} ${!picChoice ? styles.activeChoice : ''}`}
                      onClick={() => {
                        setPicChoice(false);
                      }}
                    >
                      {t('WriteInputs')}
                    </button>
                    <button
                      type="button"
                      className={`${styles.swipeChoice} ${picChoice ? styles.activeChoice : ''}`}
                      onClick={() => {
                        setPicChoice(true);
                      }}
                    >
                      {t('takePicture')}
                    </button>
                  </div>

                  {!picChoice &&
                    <div className={styles.lotDateContainer}>
                      <div className={styles.containerLot}  >
                        <div id="numLotSection" className={styles.numLotSection}>
                          <Controller
                            name="n_lot"
                            control={control}
                            render={({ field }) => (
                              <input
                                onFocus={handleFocus}
                                ref={(e) => {
                                  field.ref(e);
                                  inputRef.current = e;
                                }}
                                type="text"
                                placeholder={t('lotPlaceholder')}
                                onInput={(event) => limitInputLength(event.target, 10)}
                                maxLength="10"
                                style={{ margin: 0, width: '100%', lineHeight: 'normal', height: '41px' }}
                                {...field}
                                onChange={(e) => {
                                  // Allow only A-Z, a-z, 0-9 characters
                                  let sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');

                                  // Sanitize the input value using xss and allow only alphanumeric characters
                                  sanitizedValue = xss(sanitizedValue);

                                  // Ensure the value doesn't exceed the max length
                                  sanitizedValue = sanitizedValue.slice(0, 10);

                                  field.onChange(sanitizedValue);
                                }}
                                {...(selectedCompany && {
                                  style: {
                                    border: selectedCompany.colors.inputBorder,
                                  },
                                })}
                              />
                            )}
                          />

                        </div>
                        <div id="lot" className={styles.imagelot} style={{ display: 'none' }}></div>
                      </div>
                      <Controller
                        name="selectedDate"
                        control={control}
                        render={({ field }) => (
                          <DatePickerComponent selectedDate={field.value} setSelectedDate={field.onChange} onBlur={scrollToDateLevel} />
                        )}
                      />
                    </div>
                  }

                  {picChoice &&
                    <Controller
                      name="compressedLot"
                      control={control}
                      render={({ field }) => (
                        <LotImageUploader setValue={setValue}
                          getValues={getValues}
                        // onScroll={scrollToImgCmntContainer}
                        />
                      )}
                    />
                  }

                </>
              }
              {/* <Controller
                    name="compressedLot"
                    control={control}
                    render={({ field }) => (
                      <LotImageUploader setValue={setValue}
                        getValues={getValues}
                      // onScroll={scrollToImgCmntContainer}
                      />
                    )}
                  /> */}
            </div>

            <div className={classNames(styles.infoContainer, styles.secondContainer)} ref={typesInfoContainerRef}
              {...(selectedCompany && {
                style: {
                  boxShadow: selectedCompany.colors.containerShadow,
                },
              })}>
              <h4 className={styles.infoContainerTitle}
                {...(selectedCompany && {
                  style: {
                    color: selectedCompany.colors.containerTitle,
                  },
                })}> {t('typeQuestion')} </h4>
              <p className={styles.secondaryText}>({t('select')})</p>
              <Controller
                name="selectedTypes"
                control={control}
                render={({ field }) => (
                  <ReclamationTypes selectedTypes={field.value}
                    setSelectedTypes={field.onChange}
                    onScroll={scrollToTypeInfoContainer}
                    typesInfoContainerRef={typesInfoContainerRef}
                    containerRef={containerRef}
                    product={getValues('product')}
                    goBack={goBack} />
                )}
              />
            </div>

            <div className={classNames(styles.infoContainer, styles.secondContainer)} ref={imgCmntContainerRef}
              {...(selectedCompany && {
                style: {
                  boxShadow: selectedCompany.colors.containerShadow,
                },
              })}>
              <h4
                {...(selectedCompany && {
                  style: {
                    color: selectedCompany.colors.containerTitle,
                  },
                })}> {t('addImage')} </h4>
              <Controller
                name="compressedFiles"
                control={control}
                render={({ field }) => (
                  <ImageUploader setValue={setValue}
                    getValues={getValues}
                    onScroll={scrollToImgCmntContainer} />
                )}
              />
              {/** Popup showed on click on Ajouter un commentaire, user writes its comments in and saves it */}
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <>
                    <Comment showModalComment={showModalComment}
                      isButtonVisible={isCommentButtonVisible}
                      onScroll={scrollToImgCmntContainer} />
                    <CommentTextArea isTextareaVisible={isTextareaVisible} comment={field.value} showModalComment={showModalComment} />
                  </>
                )}
              />
            </div>

            <div className={classNames(styles.infoContainer, styles.onTopContainer)} ref={locationContainerRef}
              {...(selectedCompany && {
                style: {
                  boxShadow: selectedCompany.colors.containerShadow,
                },
              })}>
              <h4
                {...(selectedCompany && {
                  style: {
                    color: selectedCompany.colors.containerTitle,
                  },
                })}> {t('fromWhere')} </h4>
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <DropDownLocation
                    wilaya={field.value?.wilaya || ''}
                    commune={field.value?.commune || ''}
                    setWilaya={(value) => setValue('location', { ...getValues('location'), wilaya: value })}
                    setCommune={(value) => setValue('location', { ...getValues('location'), commune: value })}
                    onScroll={scrollToLocationContainer}
                  />
                )}
              />

              {/** Once phone number is added hide question + OUI and NON radio button */}
              {/* <p className={styles.questionSentenceSecond}>
                Souhaitez-vous être contacté par le service consommateur ?
              </p>
              {!isInputPhoneVisible && (
                <>

                  <Phone showModalPhone={showModalPhone} resetRadioButton={resetRadioButton} />
                </>
              )} */}
            </div>
            <div className={styles.infoContainer}
              {...(selectedCompany && {
                style: {
                  boxShadow: selectedCompany.colors.containerShadow,
                },
              })}>
              <h4
                style={{ marginBottom: '0px' }}
                {...(selectedCompany && {
                  style: {
                    color: selectedCompany.colors.containerTitle,
                  },
                })}> {t('PersonalInfo')} </h4>
              <p className={styles.providePhone}> {t('instructionPerso')} </p>
              <Controller
                name="nom"
                control={control}
                rules={{
                  pattern: {
                    value: /^[A-Za-z\u0600-\u06FF\s]+$/,
                    message: "Seulement les alphabets sont permis",
                  },
                  maxLength: {
                    value: 25,
                    message: "Maximum length is 25 characters",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <input
                      type="text"
                      placeholder={t('namePlaceholder')}
                      onInput={(event) => limitInputLength(event.target, 20)}
                      maxLength="25"
                      {...field}
                      onChange={(e) => {
                        const sanitizedValue = xss(e.target.value);
                        // Only keep alphabetic characters
                        const alphabeticValue = sanitizedValue.replace(/[^A-Za-z\u0600-\u06FF\s]/g, '');
                        field.onChange(alphabeticValue);
                      }}
                      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                      lang={i18n.language === 'ar' ? 'ar' : 'fr'}  // Set the language for keyboard input
                      inputMode={i18n.language === 'ar' ? 'text' : 'latin'}  // Suggest Arabic or Latin keyboard
                      className={styles.nameInput}
                      {...(selectedCompany && {
                        style: {
                          color: selectedCompany.colors.iconColor,
                          border: selectedCompany.colors.inputBorder
                        },
                      })}
                    />
                    {error && <p>{error.message}</p>}
                  </>
                )}
              />
              {/* <p className={styles.providePhone}> {t('provideYourPhoneNumber')} </p> */}
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <input
                    type='tel'
                    placeholder={t('phonePlaceholder')}
                    onInput={(event) => limitInputLength(event.target, 14)}
                    maxLength="14"
                    style={{ margin: 0, width: '100%', direction: 'ltr' }}
                    {...field}
                    value={formatPhoneNumber(field.value || '')}
                    onChange={(e) => {
                      // Sanitize the input value using xss
                      const sanitizedValue = xss(e.target.value);
                      field.onChange(sanitizedValue);
                    }}
                    dir='ltr'
                    className={i18n.language === 'ar' ? styles.phoneInputWrapperAr : ''}
                    {...(selectedCompany && {
                      style: {
                        color: selectedCompany.colors.iconColor,
                        border: selectedCompany.colors.inputBorder,
                        margin: 0, width: '100%', direction: 'ltr'
                      },
                    })}
                  // dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                  />

                )}
              />
              <Controller
                name="mail"
                control={control}
                render={({ field }) => (
                  <>
                    <input
                      type='email'
                      placeholder={t('mailPlaceholder')}
                      onInput={(event) => limitInputLength(event.target, 64)}
                      maxLength="64"
                      style={{ width: '100%', direction: 'ltr' }}
                      {...field}
                      value={formatMail(field.value || '')}
                      onChange={(e) => {
                        const sanitizedValue = xss(e.target.value);
                        field.onChange(sanitizedValue);
                      }}
                      dir='ltr'
                      className={i18n.language === 'ar' ? styles.phoneInputWrapperAr : ''}
                      {...(selectedCompany && {
                        style: {
                          color: selectedCompany.colors.iconColor,
                          border: selectedCompany.colors.inputBorder,
                          width: '100%', direction: 'ltr'
                        },
                      })}
                    // dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                    />
                    {emailError && (
                      <span style={{ color: 'red', fontSize: '12px' }}>
                        {t('unvalidMail')}
                      </span>
                    )}
                  </>
                )}
              />
            </div>

            <div className={styles.navButtons}>
              <button id="Annuler" className={styles.annuler} type="button" onClick={goBack}>
                {t('cancel')}
              </button>
              <button className={styles.envoyer} type="submit" name="envoyer" id="Envoyer"
                {...(selectedCompany && {
                  style: {
                    background: selectedCompany.colors.formGradient,
                  },
                })}>
                {t('send')}
              </button>
            </div>
          </form>
        </div>
        {/* <InstallPrompt onInstallClick={onInstallClick} t={t} isPWAInstallable={isPWAInstallable && !isIos} /> */}
        <InstallPrompt onInstallClick={onInstallClick} t={t} isPWAInstallable={isPWAInstallable} />

        {/** When user enters last 6 numbers of bar code and many products their barcode 
        ending with these numbers are found a modal with a list of their names is displayed 
        and user can select a one.  */}
        <Suspense>
          <FailureToast text={t('fillAllFieldsMessage')} showToast={showToastFields} setShowToast={setShowToastFields} />
          {/* <InstallPopup isPWAInstallable={isPWAInstallable} onInstallClick={onInstallClick} /> */}
          <ProductListModal
            products={selectedProducts}
            isVisible={isProductListModalVisible}
            setIsVisible={setProductListModalVisible}
            setProduct={(product) => setValue('product', product)}
            setProductVisibility={setProductInputVisible}
            setProductInputsVisibility={setBarCodeVisible}
          />
          {/** On choosing OUI radio button popup is displayed to enter phone number */}
          <PhoneModal
            isVisible={isModalPhoneVisible}
            hideModal={hideModalPhone}
            setPhone={setPhone}
            phoneInputVisivlity={setInputPhoneVisible}
            setValue={setValue}
            resetRadioButton={() => setResetRadioButton(false)}
          />

          {/** On clicking on Ajouter un commentaire this popup to enter comment is displayed */}
          <CommentModal
            hideModalComment={hideModalComment}
            isVisible={isModalCommentVisible}
            setTextareaVisible={setTextareaVisible}
            setTextAreaText={setComment}
            isTextareaVisible={isTextareaVisible}
            setButtonVisibility={setCommentButtonVisible}
            comment={comment}
            setValue={setValue}
          />

          {/** On clicking on button Voir exemple of code bar popup ModalImage is shown 
          with image of bar code as an illusta-ration for user */}
          <ModalImage
            hideModalImage={hideModalImage}
            isVisible={isModalBarCodeVisible}
          />

          {/** When user enters last 6 numbers of bar code and no product corresponds this modal is shown */}
          <NotFoundModal
            isVisible={NotFoundModalVisible}
            setNotFoundModalVisible={setNotFoundModalVisible}
            onClose={handleModalNotFoundClose}
          />

          {/** If user enters an unknown same bar code for 2nd time this popup is displayed so he can add product name */}
          <UnknownProductModal
            isVisible={UnknownProductModalVisible}
            setProduct={(product) => setValue('product', product)}
            setVisibility={setUnknownProductModalVisible}
            setProductInputsVisibility={setBarCodeVisible}
            setProductVisibility={setProductInputVisible}
          />

          {/** On first time focusing on input of LotModal this popup is displayed to illustrate where
          consumer can find LotModal */}
          <LotModal
            isVisible={isModalLotVisible}
            setIsVisible={setModalLotVisible}
            focusLotInput={focusLotInput}
          />

          <ThanksPopup
            isVisible={isThanksPopUpVisible}
            updatePage={resetFormAndState}
            isPWAInstallable={isPWAInstallable}
            onInstallClick={onInstallClick}
          />

          <ProdListPopup
            isVisible={popUpProdListVisibility}
            setIsVisible={setPopUpProdListVisibility}
            setProduct={(product) => setValue('product', product)}
            setProductVisibility={setProductInputVisible}
            setCodeBarreVisibility={setBarCodeVisible}
          />


        </Suspense>
      </FormProvider>
    </div>
  );
};

function hideProductInput() { }

function limitInputLength(target, max) {
  // console.log(target.value, target.value.length, target);
  console.log(target.value.trim(0, 25));
  if (target.value.length > max) {
    target.value = target.value.substring(0, 25);
  }
}

export default Reclamation;
