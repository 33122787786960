import React, { useRef, useEffect, useState } from 'react';
import styles from '../css/reclamation.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { backendLink } from '../config';
import { companies } from './companies';

export const ReclamationTypesDistributor = ({
  selectedTypes,
  setSelectedTypes,
  onScroll,
  typesInfoContainerRef,
  containerRef,
  product,
  setContainerProdVisible,
  setProduct
}) => {
  const { t } = useTranslation();
  const scrolledItems = useRef(new Set());
  const [types, setTypes] = useState([
    "Problème d'emballage",
    "Problème d'étiquetage/dateur",
    "Long délai de livraison",
    "Problème de qualité du produit",
    "Erreur de code-barres",
    "Rupture de stock fréquente",
    "Erreur sur commande",
    "Casse des prix",
    "Date d'expiration courte",
    "Politique de retour non respectée",
    "Quantité livrée insuffisante",
    "Autre",
  ]);
  const [hideTypes, setHideTypes] = useState(["Erreur sur commande"]);

  const selectType = (type, element) => {
    let updatedSelectedTypes;

    if (selectedTypes.includes(type)) {
      // If the type is already selected, remove it
      updatedSelectedTypes = selectedTypes.filter((t) => t !== type);
    } else {
      // If the type is not selected, add it to the selection
      if (selectedTypes.length >= 2) {
        // Limit selection to 2 items
        updatedSelectedTypes = [...selectedTypes.slice(1), type];
      } else {
        updatedSelectedTypes = [...selectedTypes, type];
      }
    }

    setSelectedTypes(updatedSelectedTypes);

    // Check if all selected types are from hideTypes
    const allHidden = updatedSelectedTypes.every((t) => hideTypes.includes(t));

    // If all selected types are in hideTypes, hide the container
    if (allHidden) {
      setContainerProdVisible(false);
      setProduct([]);
    } else {
      // If at least one type is not in hideTypes, show the container
      setContainerProdVisible(true);
    }

    // Scroll the selected type into view if not already scrolled
    if (!scrolledItems.current.has(type)) {
      scrollToTypeInfoContainer(element);
      scrolledItems.current.add(type);
    }
  };

  const scrollToTypeInfoContainer = (element) => {
    if (element && containerRef.current) {
      const container = containerRef.current;
      const currentScrollTop = container.scrollTop;
      const elementRect = element.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const elementPosition =
        elementRect.top - containerRect.top + currentScrollTop;
      const containerHeight = container.clientHeight;
      const scrollPosition =
        elementPosition - containerHeight / 2 + elementRect.height / 2;

      if (scrollPosition > currentScrollTop) {
        container.scrollTo({
          top: scrollPosition,
          behavior: "smooth", // Smooth scroll
        });
      }
    }
  };

  // Company switch
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    loadSelectedCompany();
    window.addEventListener("storage", loadSelectedCompany);

    return () => {
      window.removeEventListener("storage", loadSelectedCompany);
    };
  }, [companyName]);

  return (
    <div className={styles.productGrid} ref={typesInfoContainerRef}>
      {types.map((type) => (
        <div
          className={classNames(styles.selectItem, {
            [styles.selectItemSelected]: selectedTypes.includes(type),
          })}
          key={type}
          onClick={(e) => selectType(type, e.currentTarget)}
          {...(selectedCompany && {
            style: {
              border: selectedCompany.colors.borderTypeColor,
              color: selectedCompany.colors.typeTextColor,
            },
          })}
        >
          {t(`types.${type}`)}
        </div>
      ))}
    </div>
  );
};

