import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './pages/Landing';
import Reclamation from './pages/Reclamation';
import ReclamationDistributor from './pages/ReclamationDistributor';
import LoginDistributor from './pages/LoginDistributor';
import InscriptionCommercant from './pages/InscriptionCommercant';

// import ServiceWorkerRegistration from './components/ServiceWorkerRegistration';

const App = () => {
  // const initialFlag = '/react/assets/images/flag_algeria.png';

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isPWAInstallable, setIsPWAInstallable] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const [currentLang, setCurrentLang] = useState('ar');

  // Change the lang attribute of the html tag based on language state
  useEffect(() => {
    document.documentElement.setAttribute('lang', currentLang);
  }, [currentLang]);

  
  // useEffect(() => {
  //   const checkInstallationStatus = () => {
  //     const isStandaloneMode =
  //       window.matchMedia('(display-mode: standalone)').matches ||
  //       window.navigator.standalone === true ||
  //       document.referrer.includes('android-app://');

  //     if (isStandaloneMode) {
  //       setIsPWAInstallable(false);
  //     } else {
  //       const handler = (e) => {
  //         e.preventDefault();
  //         setDeferredPrompt(e);
  //         setIsPWAInstallable(true);
  //       };

  //       window.addEventListener('beforeinstallprompt', handler);

  //       // Fallback timer
  //       const fallbackTimer = setTimeout(() => {
  //         setIsPWAInstallable(true);
  //       }, 2000);

  //       return () => {
  //         window.removeEventListener('beforeinstallprompt', handler);
  //         clearTimeout(fallbackTimer);
  //       };
  //     }
  //   };

  //   checkInstallationStatus();

  //   // Add listener for changes in display mode
  //   const displayModeMediaQuery = window.matchMedia('(display-mode: standalone)');
  //   displayModeMediaQuery.addListener(checkInstallationStatus);

  //   return () => {
  //     displayModeMediaQuery.removeListener(checkInstallationStatus);
  //   };
  // }, []);

  //  // For iOS devices to display instructions
  //  useEffect(() => {
  //   const isStandaloneMode =
  //     window.matchMedia('(display-mode: standalone)').matches ||
  //     window.navigator.standalone === true ||
  //     document.referrer.includes('android-app://');

  //   // Check if the platform is iOS
  //   const userAgent = window.navigator.userAgent.toLowerCase();
  //   const isIosPlatform = /iphone|ipad|ipod/.test(userAgent);
  //   setIsIos(isIosPlatform && !isStandaloneMode);

  // }, []);

  // useEffect(() => {
  //   const handleBeforeInstallPrompt = (e) => {
  //     e.preventDefault();
  //     setDeferredPrompt(e);
  //     setIsPWAInstallable(true);
  //     console.log('beforeinstallprompt event was fired');
  //   };

  //   window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

  //   return () => {
  //     window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  //   };
  // }, []);

  // const handleInstallClick = () => {
  //   console.log('install click');
  //   if (deferredPrompt) {
  //     console.log('deffered prompt');
  //     deferredPrompt.prompt();
  //     deferredPrompt.userChoice.then((choiceResult) => {
  //       if (choiceResult.outcome === 'accepted') {
  //         console.log('User accepted the PWA prompt');
  //         setIsPWAInstallable(false);
  //       } else {
  //         console.log('User dismissed the PWA prompt');
  //       }
  //       setDeferredPrompt(null);
  //       // setIsPWAInstallable(false);
  //     });
  //   } else{
  //     console.log('prompt false');
      
  //     // alert("Pour installer l'application, ajouter cette page à votre écran d'accueil ");
  //   }
  // };

  // const handleInstallClick = () => {
  //   console.log('Install click');
  //   if (deferredPrompt) {
  //     console.log('Deferred prompt available');
  //     deferredPrompt.prompt();
  //     deferredPrompt.userChoice.then((choiceResult) => {
  //       if (choiceResult.outcome === 'accepted') {
  //         console.log('User accepted the PWA prompt');
  //       } else {
  //         console.log('User dismissed the PWA prompt');
  //       }
  //       setDeferredPrompt(null);
  //       setIsPWAInstallable(false);
  //     });
  //   } else {
  //     console.log('Deferred prompt not available');
  //     // Handle cases where the prompt isn't available
  //   }
  // };

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      console.log('beforeinstallprompt event captured');
      setDeferredPrompt(e);
      setIsPWAInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    
    // Check if the app is already installed
    const checkInstallationStatus = () => {
      const isStandaloneMode =
        window.matchMedia('(display-mode: standalone)').matches ||
        window.navigator.standalone === true ||
        document.referrer.includes('android-app://');

      setIsPWAInstallable(!isStandaloneMode);
    };

    checkInstallationStatus();

    // Check for iOS
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIosPlatform = /iphone|ipad|ipod/.test(userAgent);
    setIsIos(isIosPlatform && !window.navigator.standalone);
    
    // Ensure listener isn't removed prematurely
    return () => {
      if (!deferredPrompt) {
        // console.log('Removing beforeinstallprompt listener because deferredPrompt was not set');
      } else {
        // console.log('Removing beforeinstallprompt listener because it was captured');
      }
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
}, [deferredPrompt]);



  const handleInstallClick = () => {
    console.log('Install click');
    if (deferredPrompt) {
      console.log('Deferred prompt available');
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the PWA prompt');
        } else {
          localStorage.setItem('canceledInstallation', 'true'); // Set to true if the user cancels
          console.log('User dismissed the PWA prompt');
        }
        setDeferredPrompt(null);
        setIsPWAInstallable(false);
      });
    } else {
      console.log('Deferred prompt not available');
      // For iOS devices, show instructions to add to home screen
      if (isIos) {
        alert("To install the app, tap the share button and then 'Add to Home Screen'");
      } else {
        alert("Installation is not available at the moment. Please make sure you're using a supported browser and the app meets the installation criteria.");
      }
    }
  };

  return (
    <Router basename="/user">
      <Routes> 
        {/* <Route path="/" element={<FirstPage isPWAInstallable={isPWAInstallable} onInstallClick={handleInstallClick} isIos={isIos} currentLang={currentLang} setCurrentLang={setCurrentLang} />} />  */}
        <Route path="/" element={<Landing isPWAInstallable={isPWAInstallable} onInstallClick={handleInstallClick} isIos={isIos} currentLang={currentLang} setCurrentLang={setCurrentLang} />} /> 
        <Route path="/login_d" element={<LoginDistributor />}  /> 
        <Route path="/inscription_d" element={<InscriptionCommercant currentLang={currentLang} setCurrentLang={setCurrentLang} />} /> 
        <Route path="/reclamation_d" element={<ReclamationDistributor isPWAInstallable={isPWAInstallable} onInstallClick={handleInstallClick} isIos={isIos} currentLang={currentLang} setCurrentLang={setCurrentLang} />} /> 
        <Route path="/reclamation_q" element={<Reclamation isPWAInstallable={isPWAInstallable}  onInstallClick={handleInstallClick} isIos={isIos} currentLang={currentLang} setCurrentLang={setCurrentLang} />} />
      </Routes>
      
      {/* <ServiceWorkerRegistration /> */}
    </Router>
  );
} 

export default App;
