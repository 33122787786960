import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from '../css/reclamation.module.scss'; // Adjust the path to your CSS module
import '../css/confetti.min.scss';
import Confetti from './Confetti';

const ThanksPopup = ({ isVisible, updatePage, isPWAInstallable, onInstallClick }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onCancelClick = () => {
    navigate('/');
  }

  return (
    <div className={`${styles.modal} ${!isVisible ? styles.modalHidden : ''}`}>
      <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className={styles.thanksPopupContainer}>
          <Confetti />
          <div className={styles.thanksPopupTextContainer} >
          <div className={styles.message}>
            {t('thanksSentence')}
          </div>
          <div className={styles.emoji}>😊</div>
          </div>
          {/* <div id="progress">Envoi en cours ..</div> */}
          {/* <p className={styles.parQuestion}> {t('addReclamationQuestion')} </p>
          <div style={{ display: 'flex', gap: '15px', zIndex: 3000 }}>
            <button type='button' onClick={() => navigate('/')} className={`${styles.nonButton} ${styles.popUpButton}`}> {t('No')} </button>
            <button type='button' onClick={() => updatePage()} className={`${styles.ouiButton} ${styles.popUpButton}`}> {t('oui')} </button>
          </div> */}
          {isPWAInstallable ?
            <>
              <p className={styles.installContPar}>{t('installQuestion')}</p>
              <div className={styles.installButtonsContainer}>
                {isPWAInstallable &&
                  <button
                    className={styles.cancelInstallButton}
                    onClick={onCancelClick}
                    id="installButton"
                  >
                    {t('non')}
                  </button>
                }

                <button
                  className={styles.installButton}
                  onClick={onInstallClick}
                  id="installButton"
                // {...(selectedCompany && {
                //   style: {
                //     backgroundColor: selectedCompany.colors.secondColor,
                //   },
                // })}
                >
                  {t('oui')}
                </button>
              </div>
            </>
            :
            (<button className={styles.doneButton} onClick={onCancelClick}>
              {t('done')}
            </button>)
          }


        </div>
      </div>
    </div>
  );
};

export default ThanksPopup;
