import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../assets/images/logo.png';
import styles from '../css/reclamation.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { LanguageSwitcher } from './LanguageSwitcher';
import { companies } from './companies';

const Header = ({ isSticky, currentLang, setCurrentLang }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/', { replace: false, state: { preventReload: true } });
  };

  //Switch company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {

      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts


  return (
    <div className={classNames(styles.head, { [styles.sticky]: isSticky })}>
      <div className={classNames(styles.backArrowContainer, { [styles.sticky]: isSticky })}>
        <button
          style={{    border: 'none',
          background: 'transparent',
          width: 'fit-content'}}
          type='button' id="backButton" onClick={goBack}>
          <FontAwesomeIcon className={styles.back} icon={faAngleLeft}
            {...(selectedCompany && {
              style: {
                color: selectedCompany.colors.textColor,
              },
            })}
          />
        </button>
        <img
          className={styles.logoHeader}
          src={selectedCompany ? selectedCompany.logo : logo}
          alt={selectedCompany ? `${selectedCompany.name} Logo` : "Default Logo"}
        />
        <LanguageSwitcher currentLang={currentLang} setCurrentLang={setCurrentLang} />
      </div>
    </div>
  );
};

export default Header;
