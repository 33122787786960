import React, {Suspense, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // eslint-disable-line react/react-in-jsx-scope
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import InstallPrompt from '../components/InstallPrompt';
import MetaTags from '../components/MetaTagsIndex';
import Header from '../components/Header';
import {LanguageSwitcher} from '../components/LanguageSwitcher';
import bg from '../assets/images/bg.png';
import styles from '../css/landing.module.scss';
import AlertModal from '../components/AlertModal.js';
import TermsOfUse from '../components/TermsOfUse';

// const TermsOfUse = React.lazy(() => import('../components/TermsOfUse').then(module => ({ default: module.TermsOfUse })));


const LoginDistributor = ({isPWAInstallable, onInstallClick, isIos, currentLang, setCurrentLang, flag, setFlag}) => {
  const { t, i18n } = useTranslation();
  const [isTermsOfUseModalVisible, setIsTermsOfUseModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  // const [isTermsOfUseModalVisible, setIsTermsOfUseModalVisible] = useState(true);

  // const onAccept = () =>{
  //   localStorage.setItem('conditionsAccepted', 'true');
  //   setIsTermsOfUseModalVisible(false);
  //   console.log('user accepted');
  // }

  // useEffect(() => {
  //   const conditionsAccepted = localStorage.getItem('conditionsAccepted');
  //   if (conditionsAccepted) {
  //     setIsTermsOfUseModalVisible(false);
  //   }
  // }, []);

  // const onRefuse = () =>{

  // }

  // Force language to French on first load
  React.useEffect(() => {
    if (!localStorage.getItem('i18nextLng')) {
      i18n.changeLanguage('fr');
    }
  }, [i18n]);

  const onAccept = () =>{
    localStorage.setItem('conditionsAccepted', 'true');
    setIsTermsOfUseModalVisible(false);
    navigate('/reclamation_q');
  }

  const navigate = useNavigate()

  const onRefuse = () =>{
    setIsTermsOfUseModalVisible(false);
  }


  

  const goToDistributorForm = () =>{
    const conditionsAccepted = localStorage.getItem('conditionsAccepted');
    if (conditionsAccepted) {
      navigate('/reclamation_d');
    }else{
      setIsTermsOfUseModalVisible(true);
    }
  }

  return(
  <div className={styles.main_container}>
  <MetaTags />
  <LanguageSwitcher currentLang={currentLang} setCurrentLang={setCurrentLang} flag={flag} setFlag={setFlag} />
  <Header />
  <p className={styles.B2B}>B2B</p>
  <div className={styles.formImgContainer}>
    <form className={styles.myForm}>
      {/* <p className={styles.declarer}>{t('Bienvenue')}</p> */}
      <div className={styles.log_product} style={{marginTop: '40px'}}>
        <button type='button' onClick={goToDistributorForm} className={classNames(styles.navigation_button, styles.espace_product)} id="patn1" name="prod" value="QualityForm">
        {t('login')}
        </button>
        <Link to="/inscription_d" className={styles.blueUnderline} >
          {t('signup')}
        </Link>
      </div>
    </form>
    {/* <img className={styles.imageBg} src={bg} alt="Jawda" /> */}
  </div>
  <AlertModal open={open} setOpen={setOpen} />
  <TermsOfUse isVisible={isTermsOfUseModalVisible} onAccept={onAccept} onRefuse={onRefuse}  />
  
  {/* <Suspense>
    <TermsOfUse isVisible={isTermsOfUseModalVisible} onAccept={onAccept} onRefuse={onRefuse}  />
  </Suspense> */}
  
  </div>
)} ;

export default LoginDistributor;
