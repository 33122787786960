import React, { Suspense, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // eslint-disable-line react/react-in-jsx-scope
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import InstallPrompt from '../components/InstallPrompt';
import MetaTags from '../components/MetaTagsIndex';
import Header from '../components/Header';
import { LanguageSwitcher } from '../components/LanguageSwitcher';
import styles from '../css/reclamation.module.scss';
import AlertModal from '../components/AlertModal.js';
import TermsOfUse from '../components/TermsOfUse';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import xss from 'xss';
import { backendLink } from '../config.js';
import { DropDown, DropDownLocation, DropDownType } from '../components/DropDown';
import { FailureToast, PopUpAccountCreation } from '../components/SuccessToast.js';
import { Toast, ToastContainer } from 'react-bootstrap';
// const TermsOfUse = React.lazy(() => import('../components/TermsOfUse').then(module => ({ default: module.TermsOfUse })));


const InscriptionCommercant = ({ isPWAInstallable, onInstallClick, isIos, currentLang, setCurrentLang, flag, setFlag }) => {
    const { t, i18n } = useTranslation();
    const [isTermsOfUseModalVisible, setIsTermsOfUseModalVisible] = useState(false);
    const [open, setOpen] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToastFields, setShowToastFields] = useState(false);
    const [successPopup, setSuccessPopup] = useState(false);
    const [codeUser, setCodeUser] = useState('');
    const methods = useForm({
        defaultValues: {
            selectedTypes: [],
            location: { wilaya: '', commune: '' },
            product: '',
            n_lot: '',
            selectedDate: null,
            comment: '',
            phone: '',
            compressedFiles: [],
            type: '',
            email: ''
        },
    });

    useEffect(() => {
        console.log('language', currentLang);
        i18n.changeLanguage(currentLang);
    }, [currentLang, i18n]);

    const { handleSubmit, control, setValue, getValues } = methods;

    const formatPhoneNumber = (value) => {
        // Remove all non-numeric characters
        const digits = value.replace(/\D/g, '');

        // Format the number with spaces after every two digits
        const formatted = digits.match(/.{1,2}/g)?.join(' ') || '';

        return formatted.trim();
    };

    const onAccept = () => {
        localStorage.setItem('conditionsAccepted', 'true');
        setIsTermsOfUseModalVisible(false);
        navigate('/reclamation_q');
    }

    const navigate = useNavigate()

    const onRefuse = () => {
        setIsTermsOfUseModalVisible(false);
    }

    const goToDistributorForm = () => {
        const conditionsAccepted = localStorage.getItem('conditionsAccepted');
        if (conditionsAccepted) {
            navigate('/reclamation_d');
        } else {
            setIsTermsOfUseModalVisible(true);
        }
    }

    const onSubmit = async (data, event) => {
        event.preventDefault();

        // Check if any required fields are empty
        const requiredFields = [
            'nomComplet',
            // 'location.wilaya', 
            // 'location.commune', 
            'phone',
            'num_reg',
            'type',
        ];

        // Check if any required field is missing
        const missingFields = requiredFields.filter(field => {
            const fieldValue = field.split('.').reduce((obj, key) => obj[key], data);
            return !fieldValue || fieldValue.trim() === '';
        });

        if (missingFields.length > 0) {
            console.log('missing fields');
            setShowToastFields(true);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('nomComplet', data.nomComplet ?? '');
            formData.append('region', data.location.wilaya);
            formData.append('commune', data.location.commune);
            formData.append('phone', data.phone);
            formData.append('num_reg', data.num_reg);
            formData.append('type', data.type);
            formData.append('email', data.email);
            formData.append('envoyer', true);

            const response = await fetch(`${backendLink}create_commerce_account.php`, {
                method: 'POST',
                body: formData,
                credentials: 'include' // Include cookies for CSRF protection
            });

            if (response.status === 200) {
                const data = await response.json();
                setSuccessPopup(true);
                setCodeUser(data.code); // Access 'code' from the response
            } else {
                setShowToast(true);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };


    const locationContainerRef = useRef(null);

    const scrollToLocationContainer = () => {
        if (locationContainerRef.current) {
            // First, scroll it into view
            locationContainerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    const handleCancel = () => {
        navigate('/', { replace: false, state: { preventReload: true } });
    };

    return (
        <div className={styles.main_container}>
            <MetaTags />
            <div className={styles.pageHeader} >
                <LanguageSwitcher currentLang={currentLang} setCurrentLang={setCurrentLang} flag={flag} setFlag={setFlag} />
            </div>
            <div className={styles.inscriptionContainer}>
                <FormProvider {...methods}>
                    <div className={classNames(styles.infoContainerInscription, styles.onTopContainer)}>
                        <form className={styles.form} id="myform" onSubmit={handleSubmit(onSubmit)}>
                            <div className={classNames(styles.containerInscription)}>
                                <h3 className={styles.signupTile}> {t('signupTitle')} </h3>
                                <div className={styles.infoContainer}>
                                    {/* <h4> Inscription </h4> */}
                                    <Controller
                                        name="nomComplet"
                                        control={control}
                                        rules={{
                                            pattern: {
                                                value: /^[A-Za-z\u0600-\u06FF\s]+$/,
                                                message: "Seulement les alphabets sont permis",
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Maximum length is 25 characters",
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <input
                                                    style={{ padding: "10px" }}
                                                    type="text"
                                                    placeholder={t('namePlaceholder')}
                                                    onInput={(event) => limitInputLength(event.target, 20)}
                                                    maxLength="20"
                                                    {...field}
                                                    onChange={(e) => {
                                                        const sanitizedValue = xss(e.target.value);
                                                        // Only keep alphabetic characters
                                                        const alphabeticValue = sanitizedValue.replace(/[^A-Za-z\u0600-\u06FF\s]/g, '');
                                                        field.onChange(alphabeticValue);
                                                    }}
                                                    dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                                                    lang={i18n.language === 'ar' ? 'ar' : 'fr'}  // Set the language for keyboard input
                                                    inputMode={i18n.language === 'ar' ? 'text' : 'latin'}  // Suggest Arabic or Latin keyboard
                                                    className={styles.nameInput}
                                                />
                                                {error && <p>{error.message}</p>}
                                            </>
                                        )}
                                    />
                                    {/* <Controller
                                        name="prenom"
                                        control={control}
                                        rules={{
                                            pattern: {
                                                value: /^[A-Za-z\u0600-\u06FF\s]+$/,
                                                message: "Seulement les alphabets sont permis",
                                            },
                                            maxLength: {
                                                value: 20,
                                                message: "Maximum length is 20 characters",
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <input
                                                    style={{ padding: "10px" }}
                                                    type="text"
                                                    placeholder={t('surnomPlaceholder')}
                                                    onInput={(event) => limitInputLength(event.target, 20)}
                                                    maxLength="20"
                                                    {...field}
                                                    onChange={(e) => {
                                                        const sanitizedValue = xss(e.target.value);
                                                        // Only keep alphabetic characters
                                                        const alphabeticValue = sanitizedValue.replace(/[^A-Za-z\u0600-\u06FF\s]/g, '');
                                                        field.onChange(alphabeticValue);
                                                    }}
                                                    dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                                                    lang={i18n.language === 'ar' ? 'ar' : 'fr'}  // Set the language for keyboard input
                                                    inputMode={i18n.language === 'ar' ? 'text' : 'latin'}  // Suggest Arabic or Latin keyboard
                                                    className={styles.nameInput}
                                                />
                                                {error && <p>{error.message}</p>}
                                            </>
                                        )}
                                    /> */}

                                    <Controller
                                        name="phone"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                type='tel'
                                                placeholder={t('phonePlaceholder')}
                                                onInput={(event) => limitInputLength(event.target, 14)}
                                                maxLength="14"
                                                style={{ width: '100%', direction: 'ltr' }}
                                                {...field}
                                                value={formatPhoneNumber(field.value || '')}
                                                onChange={(e) => {
                                                    // Sanitize the input value using xss
                                                    const sanitizedValue = xss(e.target.value);
                                                    field.onChange(sanitizedValue);
                                                }}
                                                dir='ltr'
                                                className={i18n.language === 'ar' ? styles.phoneInputWrapperAr : ''}
                                            // dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                                            />
                                        )}
                                    />
                                    {/* <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                type='email' // Changed from 'mail' to 'email' for proper validation
                                                placeholder={t('mailPlaceholder')}
                                                maxLength="14"
                                                style={{
                                                    width: '100%',
                                                    direction: i18n.language === 'ar' ? 'rtl' : 'ltr', // Placeholder direction based on language
                                                }}
                                                {...field}
                                                value={formatMailNumber(field.value)}
                                                onChange={(e) => {
                                                    // Sanitize the input value using xss
                                                    const sanitizedValue = xss(e.target.value);
                                                    field.onChange(sanitizedValue);
                                                }}
                                                onInput={(event) => limitInputLength(event.target, 14)}
                                                dir="ltr" // Ensure the value is always in ltr direction
                                                className={i18n.language === 'ar' ? styles.phoneInputWrapperAr : ''}
                                            />
                                        )}
                                    /> */}

                                    <Controller
                                        name="location"
                                        control={control}
                                        render={({ field }) => (
                                            <DropDownLocation
                                                wilaya={field.value?.wilaya || ''}
                                                commune={field.value?.commune || ''}
                                                setWilaya={(value) => setValue('location', { ...getValues('location'), wilaya: value })}
                                                setCommune={(value) => setValue('location', { ...getValues('location'), commune: value })}
                                                onScroll={scrollToLocationContainer}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="type"
                                        control={control}
                                        render={({ field }) => (
                                            <DropDownType
                                                type={field.value}
                                                setType={field.onChange}
                                                onScroll={scrollToLocationContainer}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="num_reg"
                                        control={control}
                                        rules={{
                                            pattern: {
                                                value: /^[A-Za-z0-9]+$/,
                                                message: "Seulement les alphabets sont permis",
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Maximum length is 25 characters",
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <input
                                                    type='text'
                                                    style={{ padding: "10px" }}
                                                    placeholder={t('registrePlaceholder')}
                                                    onInput={(event) => limitInputLength(event.target, 25)}
                                                    maxLength="25"
                                                    {...field}
                                                    onChange={(e) => {
                                                        const sanitizedValue = xss(e.target.value);
                                                        // Only keep alphanumeric characters (letters and numbers)
                                                        const alphabeticValue = sanitizedValue.replace(/[^A-Za-z0-9]/g, '');  // Corrected line
                                                        field.onChange(alphabeticValue);
                                                    }}
                                                    dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                                                    lang={i18n.language === 'ar' ? 'ar' : 'fr'}  // Set the language for keyboard input
                                                    inputMode={i18n.language === 'ar' ? 'text' : 'latin'}  // Suggest Arabic or Latin keyboard
                                                    className={styles.nameInput}
                                                />
                                                {error && <p>{error.message}</p>}
                                            </>
                                        )}
                                    />


                                </div>
                            </div>
                            <div className={styles.navButtons}>
                                <button id="Annuler" className={styles.annuler} type="button" onClick={handleCancel}>
                                    {t('cancel')}
                                </button>
                                <button className={styles.envoyer} type="submit" name="envoyer" id="Envoyer" >
                                    {t('sign-up')}
                                </button>
                            </div>
                        </form>

                    </div>
                </FormProvider>
                <FailureToast text={t('fillAllFieldsMessage')} showToast={showToastFields} setShowToast={setShowToastFields} />
                <FailureToast text={"Echec! veuillez réessayer"} showToast={showToast} setShowToast={setShowToast} />

            </div>
            {/* <Suspense>
                <TermsOfUse isVisible={isTermsOfUseModalVisible} onAccept={onAccept} onRefuse={onRefuse}  />
            </Suspense> */}
            <PopUpAccountCreation isVisible={successPopup} setVisibility={setSuccessPopup} code={codeUser} />
        </div>
    )
};

export default InscriptionCommercant;

function limitInputLength(target, max) {
    // console.log(target.value, target.value.length, target);
    if (target.value.length > max) {
        target.value = target.value.substring(0, 10);
    }
}


