import React, { useEffect, useState } from 'react';
import logo from '../assets/images/logo.png';
import styles from '../css/landing.module.scss';
import { companies } from './companies';

const Header = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companyName = localStorage.getItem("selectedCompany");

  useEffect(() => {
    const loadSelectedCompany = () => {
      
      if (companyName) {
        const company = companies.find((comp) => comp.name === companyName);
        if (company) {
          setSelectedCompany(company);
        }
      } else {
        setSelectedCompany(null);
      }
    };

    // Load the initial state
    loadSelectedCompany();

    // Add event listener for storage changes
    window.addEventListener('storage', loadSelectedCompany);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', loadSelectedCompany);
    };
  }, [companyName]); // Empty dependency array to run only once when the component mounts

  return (
    <div className={styles.header}>
      <img
        className={styles.logo}
        src={selectedCompany ? selectedCompany.logo : logo}
        alt={selectedCompany ? `${selectedCompany.name} Logo` : "Default Logo"}
      />
    </div>
  );
};

export default Header;